import React from 'react';
import { MESSAGES } from '../../conf/app-conf';

const ToastConnexion = () => {
  return (
    <div className="toast-container">
      <p>{ MESSAGES.CONNEXION_LOST }</p>
    </div>
  );
};

export default ToastConnexion;