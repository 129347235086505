import React from 'react';

const ModalMessage = ({ message }) => {
  return (
    <div className="modal-message-wrapper">
      <p>{ message }</p>
    </div>
  )
};

export default ModalMessage;