import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import NumPad from './numpad/NumPad'
import GamePlayStore from '../../../stores/GamePlayStore'
import caching from '../../../helpers/Caching'

const RootLevel = props => {
  const { room, onFindRoom2 } = props
  const [ roomTimeline ] = useState([])

  const root = useRef()
  const wrapper = useRef()

  let ratio = 0.7493
  let multiplier = 1.04

  let w = 0
  let h = 0
  let x = 0
  let y = 0
  let hSpace = 0
  let vSpace = 0
  let contentWidth = 0
  let contentHeight = 0
  let currentRatio = ratio
  let allowed = true

  const updateCoords = () => {
    if (
      root.current && root.current instanceof HTMLElement &&
      wrapper.current && wrapper.current instanceof HTMLElement
    ) {
      w = root.current.offsetWidth || root.current.clientWidth
      h = root.current.offsetHeight || root.current.clientHeight
      x = w / 2
      y = h / 2
      currentRatio = h / w

      if (wrapper.current.dataset.ratio) {
        ratio = wrapper.current.dataset.ratio
      }

      if (currentRatio > ratio) {
        contentWidth = h / ratio
        contentHeight = h
      } else {
        contentWidth = w
        contentHeight = w * ratio
      }

      if (wrapper.current.dataset.multiplier) {
        multiplier = wrapper.current.dataset.multiplier
      }

      contentWidth *= multiplier
      contentHeight *= multiplier

      hSpace = Math.floor(contentWidth - w)
      vSpace = Math.floor(contentHeight - h)

      gsap.set(
        wrapper.current,
        {
          width: contentWidth,
          height: contentHeight,
          x: (contentWidth / -2) + ((w / 2 - x) / w) * hSpace,
          y: (contentHeight / -2) + ((h / 2 - y) / h) * vSpace
        }
      )
    }
  }

  const updateMouseCoords = event => {
    const e = event || window.event
    const { pageX = 0, pageY = 0 } = e
    const rect = root.current.getBoundingClientRect()

    x = pageX - rect.left - (window.pageXOffset || window.scrollX)
    y = pageY - rect.top - (window.pageYOffset || window.scrollY)

    return { x, y }
  }

  const animate = event => {
    updateMouseCoords(event)

    if (wrapper.current && wrapper.current instanceof HTMLElement && w && h) {
      gsap.killTweensOf(wrapper.current)

      gsap.to(
        wrapper.current,
        {
          duration: wrapper.current.dataset.speed || 4,
          rotation: 0.01,
          ease: 'power2.out',
          force3D: true,
          x: (contentWidth / -2) + ((w / 2 - x) / w) * hSpace,
          y: (contentHeight / -2) + ((h / 2 - y) / h) * vSpace
        }
      )
    }
  }

  const onResize = () => {
    if (wrapper.current && wrapper.current instanceof HTMLElement) {
      gsap.killTweensOf(wrapper.current)
      gsap.set(wrapper.current, { clearProps: 'all' })
    }

    updateCoords()
  }

  const attachEventsHandlers = () => {
    window.addEventListener('resize', onResize)

    if (root.current && root.current instanceof HTMLElement) {
      root.current.addEventListener('mouseenter', animate)
      root.current.addEventListener('mousemove', animate)
    }
  }

  const toggleLevel = (event, id, level = 2, numpad = false, direct = false) => {
    if (allowed) {
      const mouse = updateMouseCoords(event)
      const x = mouse.x
      const y = mouse.y
      const w = root.current.offsetWidth || root.current.clientWidth
      const h = root.current.offsetHeight || root.current.clientHeight
      const centerX = 100
      const previous = level === 2 || direct ? root.current.querySelector('.room-level-1') : root.current.querySelectorAll('.room-level-2')
      const tgtElement = numpad ? root.current.querySelector('.numpad') : root.current.querySelector(`.room-level-${ level }-${ id }`)
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const timeline = gsap.timeline(
        {
          paused: true,
          onStart: () => {
            allowed = false
          },
          onComplete: () => {
            allowed = true
          },
          onReverseComplete: () => {
            allowed = true
          },
        }
      )

      timeline.to(
        previous,
        {
          duration: 1,
          scale: 6,
          filter: isFirefox ? 'none' : 'blur(10px)',
          transformOrigin: `${Math.round(centerX * x / w)}% ${Math.round( centerX * y / h)}%`,
          yoyo: true,
          ease: 'power2.inOut'
        },
        0
      )

      if (tgtElement && tgtElement instanceof HTMLElement) {
        timeline.fromTo(
          tgtElement,
          {
            autoAlpha: 0,
            filter: isFirefox ? 'none' : 'blur(10px)',
            y: 10,
            scale: 0.96
          },
          {
            duration: 0.4,
            autoAlpha: 1,
            y: 0,
            scale: 1,
            filter: isFirefox ? 'none' : 'blur(0px)',
            yoyo: true,
            ease: 'power2.out'
          },
          0.4
        )
      }

      timeline.to(
        root.current.querySelector('.close'),
        {
          duration: 0.6,
          autoAlpha: 1,
          ease: 'power4.out'
        },
        0.6
      )

      timeline.play()
      roomTimeline.push(timeline)
    }
  }

  const close = () => {
    if (roomTimeline.length) {
      const current = roomTimeline.pop()

      current.reverse()
    }

    GamePlayStore.setNumPadRefresh(true);
  }

  const endRoom2 = () => {
    if (onFindRoom2) {
      onFindRoom2()
    }
  }

  const ready = (element) => {
    element.target.parentNode.classList.add('ready')
  }

  useEffect(() => {
    updateCoords()
    attachEventsHandlers()

    if (room) {
      caching.changePriority(parseInt(room))
    }

    if (root.current && root.current instanceof HTMLElement) {
      gsap.to(
        root.current.querySelector('.room-level-1'),
        {
          duration: 1,
          autoAlpha: 1
        }
      )
    }
  }, [])

  return (
    <div className="room-root-level" ref={ root }>
      <button className="close" onClick={ close }><div><span>Back</span></div></button>

      {/* Numpad */}
      { room === 1 &&
        <div className="numpad">
          <NumPad gamePlayStore={ GamePlayStore } padType="num" roomId="room1" />
        </div>
      }

      {/* Direction Pad */}
      { room === 3 &&
        <div className="numpad">
          <NumPad gamePlayStore={ GamePlayStore } padType="dir" roomId="room3" />
        </div>
      }

      { room === 1 &&
        <div className="room-wrapper room-1" ref={ wrapper }>

          {/* Level 1 */}
          <div className="room-level room-level-1">
            <img onLoad={ ready } src="S1N1.jpg" alt="" className="room-media-main"/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 1)}/>
              <div onClick={e => toggleLevel(e, 2)}/>
              <div onClick={e => toggleLevel(e, 3)}/>
              <div onClick={e => toggleLevel(e, 4)}/>
              <div onClick={e => toggleLevel(e, 10, 3, false, true)}/>
            </div>
          </div>

          {/* Level 2 */}
          <div className="room-level room-level-2 room-level-2-1">
            <img onLoad={ ready } src="S1N2Z1.jpg" alt=""/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 1, 3, true)}/>
            </div>
          </div>

          <div className="room-level room-level-2 room-level-2-2">
            <img onLoad={ ready } src="S1N2Z2.jpg" alt=""/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 2, 3)}/>
              <div onClick={e => toggleLevel(e, 3, 3)}/>
              <div onClick={e => toggleLevel(e, 4, 3)}/>
            </div>
          </div>

          <div className="room-level room-level-2 room-level-2-3">
            <img onLoad={ ready } src="S1N2Z3.jpg" alt=""/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 5, 3)}/>
              <div onClick={e => toggleLevel(e, 6, 3)}/>
            </div>
          </div>

          <div className="room-level room-level-2 room-level-2-4">
            <img onLoad={ ready } src="S1N2Z4.jpg" alt=""/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 7, 3)}/>
              <div onClick={e => toggleLevel(e, 8, 3)}/>
              <div onClick={e => toggleLevel(e, 9, 3)}/>
            </div>
          </div>

          <div className="room-level room-level-2 room-level-2-5">
            <img onLoad={ ready } src="S1N2Z5.jpg" alt=""/>
            <div className="areas">
              <div onClick={e => toggleLevel(e, 10, 3)}/>
            </div>
          </div>

          {/* Level 3*/}
          {/*
          <div className="room-level room-level-3 room-level-3-1">
            <div className="numpad">
              <NumPad gamePlayStore={ GamePlayStore } />
            </div>
          </div>
          */}

          <div className="room-level room-level-3 room-level-3-2">
            <img onLoad={ ready } src="S1N3Z2A.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-3">
            <img onLoad={ ready } src="S1N3Z2B.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-4">
            <img onLoad={ ready } src="S1N3Z2C.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-5">
            <img onLoad={ ready } src="S1N3Z3A.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-6">
            <img onLoad={ ready } src="S1N3Z3B.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-7">
            <img onLoad={ ready } src="S1N3Z4A.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-8">
            <img onLoad={ ready } src="S1N3Z4B.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-9">
            <img onLoad={ ready } src="S1N3Z4C.jpg" alt=""/>
          </div>

          <div className="room-level room-level-3 room-level-3-10">
            <img onLoad={ ready } src="S1N3Z5.jpg" alt=""/>
          </div>

        </div>
      }

      { room === 2 &&
      <div className="room-wrapper room-2" ref={wrapper}>

        {/* Level 1 */}
        <div className="room-level room-level-1">
          <img onLoad={ ready } src="S2N1.jpg" alt="" className="room-media-main"/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 1)}/>
            <div onClick={e => toggleLevel(e, 2)}/>
            <div onClick={e => toggleLevel(e, 3)}/>
            <div onClick={e => toggleLevel(e, 4)}/>
            <div onClick={e => toggleLevel(e, 5)}/>
            <div onClick={e => toggleLevel(e, 6)}/>
            <div onClick={e => toggleLevel(e, 7)}/>
            <div onClick={e => toggleLevel(e, 8)}/>
            <div onClick={e => toggleLevel(e, 9)}/>
            <div onClick={e => toggleLevel(e, 10)}/>
          </div>
        </div>

        {/* Level 2 */}
        <div className="room-level room-level-2 room-level-2-1">
          <img onLoad={ ready } src="S2N2Z02.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-2">
          <img onLoad={ ready } src="S2N2Z03.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 1, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-3">
          <img onLoad={ ready } src="S2N2Z04.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 2, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-4">
          <img onLoad={ ready } src="S2N2Z05.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 3, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-5">
          <img onLoad={ ready } src="S2N2Z06.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 4, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-6">
          <img onLoad={ ready } src="S2N2Z07.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 5, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-7">
          <img onLoad={ ready } src="S2N2Z01.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 6, 3)}/>
            <div onClick={ endRoom2 } />
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-8">
          <img onLoad={ ready } src="S2N2Z08.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 7, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-9">
          <img onLoad={ ready } src="S2N2Z09.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 8, 3)}/>
          </div>
        </div>

        <div className="room-level room-level-2 room-level-2-10">
          <img onLoad={ ready } src="S2N2Z10.jpg" alt=""/>
          <div className="areas">
            <div onClick={e => toggleLevel(e, 9, 3)}/>
          </div>
        </div>



        {/* Level 3*/}
        <div className="room-level room-level-3 room-level-3-1">
          <img onLoad={ ready } src="S2N3Z03.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-2">
          <img onLoad={ ready } src="S2N3Z04.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-3">
          <img onLoad={ ready } src="S2N3Z05.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-4">
          <img onLoad={ ready } src="S2N3Z06.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-5">
          <img onLoad={ ready } src="S2N3Z07.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-6">
          <img onLoad={ ready } src="S2N3Z01.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-7">
          <img onLoad={ ready } src="S2N3Z08.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-8">
          <img onLoad={ ready } src="S2N3Z09.jpg" alt=""/>
        </div>

        <div className="room-level room-level-3 room-level-3-9">
          <img onLoad={ ready } src="S2N3Z10.jpg" alt=""/>
        </div>

      </div>
      }

      { room === 3 &&
      <div className="room-wrapper room-3" ref={wrapper}>

        {/* Level 1 */}
        <div className="room-level room-level-1">
          <img onLoad={ ready } src="S3N1.jpg" alt="" className="room-media-main" />
          <div className="areas">
            <div onClick={e => toggleLevel(e, 1)}/>
            <div onClick={e => toggleLevel(e, 2)}/>
            <div onClick={e => toggleLevel(e, 3)}/>
            <div onClick={e => toggleLevel(e, 4)}/>
            <div onClick={e => toggleLevel(e, 5)}/>
            <div onClick={e => toggleLevel(e, 6)}/>
            <div onClick={e => toggleLevel(e, 7)}/>
            <div onClick={e => toggleLevel(e, 8)}/>
            <div onClick={e => toggleLevel(e, 9)}/>
            <div onClick={e => toggleLevel(e, 10)}/>
            <div onClick={e => toggleLevel(e, 11)}/>
            <div onClick={e => toggleLevel(e, 12, 2, true)}/>
          </div>
        </div>

        {/* Level 2 */}
        <div className="room-level room-level-2 room-level-2-1">
          <img onLoad={ ready } src="S3N2Z01.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-2">
          <img onLoad={ ready } src="S3N2Z02.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-3">
          <img onLoad={ ready } src="S3N2Z03.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-4">
          <img onLoad={ ready } src="S3N2Z04.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-5">
          <img onLoad={ ready } src="S3N2Z05.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-6">
          <img onLoad={ ready } src="S3N2Z06.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-7">
          <img onLoad={ ready } src="S3N2Z07.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-8">
          <img onLoad={ ready } src="S3N2Z08.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-9">
          <img onLoad={ ready } src="S3N2Z09.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-10">
          <img onLoad={ ready } src="S3N2Z10.jpg" alt=""/>
        </div>

        <div className="room-level room-level-2 room-level-2-11">
          <img onLoad={ ready } src="S3N2Z11.jpg" alt=""/>
        </div>

        {/*
        <div className="room-level room-level-2 room-level-2-12">
          <div className="numpad">
            <DirectionPad gamePlayStore={ GamePlayStore } />
          </div>
        </div>
        */}

      </div>
      }

      { room === 4 &&
      <div className="room-wrapper room-3" ref={wrapper} data-multiplier={ 1.01 } data-ratio={ 0.5527 } data-speed={ 30 }>

        {/* Level 1 */}
        <div className="room-level room-level-1">
          <img onLoad={ ready } src="S4N1.jpg" alt="" className="room-media-main"/>
        </div>

      </div>
      }
    </div>
  );
}

export default RootLevel;
