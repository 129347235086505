import React, { useState, useEffect } from 'react';
import ModalStore from '../../stores/ModalStore';
import ModalMessage from './ModalMessage';
import TeamStore from '../../stores/TeamStore';
import GlobalStore from '../../stores/GlobalStore';
import { LABELS, MESSAGES, PAGES } from '../../conf/app-conf';
import UserAPI from '../../services/UserAPI';
import UserStore from '../../stores/UserStore';
import GameStore from '../../stores/GameStore';

const SignOutButton = (props) => {
  const { onSignOut, theme } = props;
  const doSignOut = async () => {
    /*
    TeamAPI.removePlayer(TeamStore.teamCode, () => {
      if (onSignOut) {
        onSignOut()
      }
      
      GlobalStore.setCurrentPage(PAGES.TEAM_SELECT);
      console.log('LOG OUT!!!')
    });
    */
  };
  const getOut = () => {
    GlobalStore.setLoadingState(false);
    if (onSignOut) {
      onSignOut()
    }
    GlobalStore.setCurrentPage(PAGES.TEAM_SELECT);
  };
  const onClickSignOut = () => {
    ModalStore.showModal({
      content: <ModalMessage message={ MESSAGES.SIGN_OUT } />,
      cancelLabel: LABELS.CANCEL,
      validateLabel: LABELS.OK,
      onValidate: () => {
        GlobalStore.setLoadingState(true);
        UserAPI.signOut().then(() => {
          UserAPI.signIn().finally(() => {
            getOut();
          });
        }).catch(() => {
          getOut();
        });
      }
    });
  };
  return (
    <div className="btn-sign-out-wrapper">
      <button className={ theme } onClick={ onClickSignOut } />
    </div>
  );
};

export default SignOutButton;