import Firebase from "firebase";
import 'firebase/database';
import 'firebase/functions';
import { FIREBASE_CONF } from '../conf/app-conf';

// Initialization
Firebase.initializeApp(process.env.REACT_APP_ENV === 'prod' ? FIREBASE_CONF.BASE_CONF_PROD : FIREBASE_CONF.BASE_CONF_DEV);
const FirebaseFunctions = Firebase.functions();
if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_FIREBASE) {
  FirebaseFunctions.useEmulator('localhost', 5001);
}
export const FirebaseDB = Firebase.database();
export const FirebaseAuth = Firebase.auth();
export { FirebaseFunctions };