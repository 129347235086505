import { makeAutoObservable } from 'mobx';

class ModalStore {
  content = null;
  onCancel = () => {};
  onClose = () => {};
  onValidate = () => {};
  cancelLabel = null;
  validateLabel = null;

  constructor() {
    makeAutoObservable(this);
  }

  showModal = (modalConf) => {
    document.body.classList.add('modal-open')

    if (modalConf) {
      for (const key in modalConf) {
        this[key] = modalConf[key];
      }
    }
  };

  hideModal = () => {
    document.body.classList.remove('modal-open')

    this.content = null;
    this.onCancel = () => {};
    this.onClose = () => {};
    this.onValidate = () => {};
    this.cancelLabel = null;
    this.validateLabel = null;
  }
}

export default new ModalStore();
