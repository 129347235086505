import { action, computed, makeAutoObservable, toJS} from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import { GAME_STATUS } from '../conf/app-conf';
import ServerTime from './ServerTime';

class GameStore {
  gameCode = null;
  name = null;
  date = null;
  conf = {};
  hallOfFame = [];
  partyRankin = [];
  currentGameStatus = -1;
  
  constructor() {
    makeAutoObservable(this, {
      gameStatus: false,
      currentGameStatus: false
    });
  }
  
  clear = () => {
    this.gameCode = null;
    this.name = null;
    this.date = null;
    this.hallOfFame = [];
    this.partyRankin = [];
  };
  
  init = (gameObj) => {
    Object.keys(gameObj).forEach((key) => {
      this[key] = gameObj[key];
    });
  };
  
  setConf = (confObj) => {
    if (confObj) {
      this.conf = { ...this.conf, ...confObj }
    }
  };
  
  // Ranking helper for detecting ex eaquo ranking
  rankReducer = (accumulatorObj, item, index) => {
    const accumulatorObjClone = _.cloneDeep(accumulatorObj);
    const currentItem = { ...item, ranking: accumulatorObjClone.rankMemo };
    const accuClone = _.cloneDeep(accumulatorObjClone.result);
    const lastItem = _.last(accuClone) || {};
    
    if (lastItem.score === currentItem.score) {
      currentItem.ranking = lastItem.ranking;
      accumulatorObjClone.rankMemo = lastItem.ranking + 1;
    } else {
      currentItem.ranking = index + 1;
    }
    accuClone.push(currentItem);
    accumulatorObjClone.result = accuClone;
    return accumulatorObjClone;
  };
  
  setHallOfFame = (hofData) => {
    this.hallOfFame = hofData !== null && hofData !== undefined ? Object.values(hofData) : [] ;
    this.currentGameStatus = this.gameStatus.status;
    
    // Check
    if (this.hallOfFame.length === 0) {
      return;
    }
  
    // Get global ranking
    let global = this.globalTeamsRanking.result;
    console.log('globalTeamsRanking: ', global);
    
    // Add globalRanking field
    global = global.map((t) => {
      return {
        ...t,
        globalRanking: t.ranking
      }
    });
    
    // Get only same game code and add ranking
    let gameTeamsRanking = global.filter((teamObj) => {
      return teamObj.gamecode === this.gameCode;
    }).reduce(this.rankReducer, {result: [], rankMemo: 1}).result;
    
    this.partyRankin = _.sortBy(gameTeamsRanking, ['ranking']);
  };
  
  get globalTeamsRanking() {
    const globalSorted = _.sortBy(_.cloneDeep(this.hallOfFame), ['score']).reverse();
    return globalSorted.reduce(this.rankReducer, {result: [], rankMemo: 1})
  };
  
  get gameStatus() {
    if (!this.conf) {
      return {};
    }
    const gameStartDate = new Date(this.date);
    const serverTime = moment(ServerTime.time);
    const beforeStartUp = moment(gameStartDate).subtract(this.conf.gameWaitDuration, 'm');
    const gameDate = moment(gameStartDate);
    const endTime= moment(gameStartDate).add(this.conf.gameDuration, 'm');
    
    /* -1: before start-up, 0: start-up, 1: started, 2: ended */
    let status = GAME_STATUS.COMING;
    let duration = Math.abs(serverTime.diff(gameDate, 's'));
    
    // Is game not started
    if (serverTime.isBefore(gameDate, 's')) {
      // Is game not about to start in <this.conf.gameWaitDuration>
      if (serverTime.isSameOrAfter(beforeStartUp, 's')) {
        status = GAME_STATUS.TO_START;
      }
    } else {
      status = GAME_STATUS.STARTED;
      duration = endTime.diff(serverTime, 's');
      
      // Check game ended
      if (serverTime.isSameOrAfter(endTime, 's')) {
        status = GAME_STATUS.ENDED;
        duration = 0;
      }
    }
    /*
    console.log('serverTime: ', serverTime.format());
    console.log('gameDate: ', gameDate.format());
    console.log('endTime: ', endTime.format());
    console.log('status, duration: ', status, duration);
    console.log('*****************************');
    */
    return { status, duration };
  }
}

export default new GameStore();