import React from 'react';
import Room1Clue1 from '../../../assets/images/S1xCLUE1.jpg'
import Room1Clue2 from '../../../assets/images/S1xCLUE2.jpg'
import Room1Clue3 from '../../../assets/images/S1xCLUE3.jpg'
import Room1Clue4 from '../../../assets/images/S1xSOLUTION.jpg'

import Room2Clue1 from '../../../assets/images/S2xCLUE1.jpg'
import Room2Clue2 from '../../../assets/images/S2xCLUE2.jpg'
import Room2Clue3 from '../../../assets/images/S2xCLUE3.jpg'
import Room2Clue4 from '../../../assets/images/S2xSOLUTION.jpg'

import Room3Clue1 from '../../../assets/images/S3xCLUE1.jpg'
import Room3Clue2 from '../../../assets/images/S3xCLUE2.jpg'
import Room3Clue3 from '../../../assets/images/S3xCLUE3.jpg'
import Room3Clue4 from '../../../assets/images/S3xSOLUTION.jpg'

import Room4Clue1 from '../../../assets/images/S4xCLUES1.jpg'
import Room4Clue2 from '../../../assets/images/S4xCLUES2.jpg'
import Room4Clue3 from '../../../assets/images/S4xCLUES3.jpg'
import Room4Clue4 from '../../../assets/images/S4xSOLUTION.jpg'

const clues = {
  'room1_clue1': Room1Clue1,
  'room1_clue2': Room1Clue2,
  'room1_clue3': Room1Clue3,
  'room1_clue4': Room1Clue4,

  'room2_clue1': Room2Clue1,
  'room2_clue2': Room2Clue2,
  'room2_clue3': Room2Clue3,
  'room2_clue4': Room2Clue4,

  'room3_clue1': Room3Clue1,
  'room3_clue2': Room3Clue2,
  'room3_clue3': Room3Clue3,
  'room3_clue4': Room3Clue4,

  'room4_clue1': Room4Clue1,
  'room4_clue2': Room4Clue2,
  'room4_clue3': Room4Clue3,
  'room4_clue4': Room4Clue4
}

const loaded = element => {
  element.target.classList.add('ready')
}

const GameClue = ({ roomId, clueId }) => {
  return (
    <div className="game-clue-wrapper">
      <figure className="media-container">
        <img alt="" src={ clues[`${ roomId }_${ clueId }`] } onLoad={ loaded } />
      </figure>
    </div>
  )
};

export default GameClue;
