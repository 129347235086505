import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';

// ****** DURATION IS IN SECONDS ******

const Timer = ({ duration, onDurationElapsed, isHidden }) => {
  const formatTimer = (mmtDuration) => {
    const days = _.padStart(mmtDuration.days(), 2, '0');
    const hours = _.padStart(mmtDuration.hours(), 2, '0');
    const minutes = _.padStart(mmtDuration.minutes(), 2, '0');
    const seconds = _.padStart(mmtDuration.seconds(), 2, '0');
    const hourText = mmtDuration.hours() === 0 ? '' : `${hours}:`;
    
    let daysText = mmtDuration.days() === 0 ? '' : `${days} days `;
    daysText = mmtDuration.days() === 1 ? `${days} day ` : daysText;
    
    return `${daysText}${hourText}${minutes}:${seconds}`;
  };
  const getUnit = (mmtDuration) => {
    if (mmtDuration.days() > 0) {
      return '';
    }
    if (mmtDuration.hours() > 0) {
      return '';
    }
    if (mmtDuration.minutes() > 0) {
      return ' mins';
    }
    if (mmtDuration.seconds() > 0) {
      return ' secs';
    }
  };
  const mDuration = moment.duration(duration * 1000);
  const [timeTimer, setTimeTimer] = useState(formatTimer(mDuration));
  const [timeUnit, setTimeUnit] = useState(getUnit(mDuration));
  let interval = null;
  
  // On component did mount
  useEffect(() => {
    interval = setInterval(() => {
      duration -= 1;
      duration = duration < 0 ? 0 : duration;
      const mDuration = moment.duration(duration * 1000);
      setTimeTimer(formatTimer(mDuration));
      setTimeUnit(getUnit(mDuration));
  
      // console.log('Timer > clearInterval: durationS: ', duration);
      if (duration <= 0) {
        clearInterval(interval);
        if (onDurationElapsed) {
          onDurationElapsed();
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, []);
  
  return (
    <React.Fragment>
      {
        !isHidden && <span className="countdown">{ timeTimer }</span>
      }
      {
        timeUnit && !isHidden && <span className="time-unit">{ timeUnit }</span>
      }
    </React.Fragment>
  );
};

export default Timer;