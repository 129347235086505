import _first from 'lodash/first'

class Caching {
  id = 'lvmh-escape-game'
  supported = false
  cache = false
  cacheable =  [
    'S1N1.jpg', 'S1N2Z1.jpg', 'S1N2Z2.jpg', 'S1N2Z3.jpg', 'S1N2Z4.jpg', 'S1N2Z5.jpg', 'S1N3Z1.jpg', 'S1N3Z2A.jpg', 'S1N3Z2B.jpg', 'S1N3Z2C.jpg', 'S1N3Z3A.jpg', 'S1N3Z3B.jpg', 'S1N3Z4A.jpg', 'S1N3Z4B.jpg', 'S1N3Z4C.jpg', 'S1N3Z5.jpg',
    'S2N1.jpg', 'S2N2Z01.jpg', 'S2N2Z02.jpg', 'S2N2Z03.jpg', 'S2N2Z04.jpg', 'S2N2Z05.jpg', 'S2N2Z06.jpg', 'S2N2Z07.jpg', 'S2N2Z08.jpg', 'S2N2Z09.jpg', 'S2N2Z10.jpg', 'S2N3Z01.jpg', 'S2N3Z03.jpg', 'S2N3Z04.jpg', 'S2N3Z05.jpg', 'S2N3Z06.jpg', 'S2N3Z07.jpg', 'S2N3Z08.jpg', 'S2N3Z09.jpg', 'S2N3Z10.jpg',
    'S3N1.jpg', 'S3N2Z01.jpg', 'S3N2Z02.jpg', 'S3N2Z03.jpg', 'S3N2Z04.jpg', 'S3N2Z05.jpg', 'S3N2Z06.jpg', 'S3N2Z07.jpg', 'S3N2Z08.jpg', 'S3N2Z09.jpg', 'S3N2Z10.jpg', 'S3N2Z11.jpg', 'S3N2Z12.jpg',
    'S4N1.jpg'
  ]

  constructor() {
    console.log('--------------------------')
    console.log('🏕 START CACHING PROCESS')
    console.log('--------------------------')

    this.cacheable = this.cacheable.map(
      element => {
        const room = element.match(/^S([0-9])/)

        return {
          room: room[1],
          file: element
        }
      }
    )
  }

  start () {
    this.supported = 'caches' in window
    this.active = true

    if (this.supported && window.caches) {
      window.caches
        .open(this.id)
        .then(
          cache => {
            this.cache = cache
            this.processCaching()
          }
        )
        .catch(
          error => {
            console.error(error.message)
          }
        )
    }
  }

  async processCaching () {
    const active = _first(this.cacheable)

    if (active) {
      const queue = () => {
        return new Promise(
          (resolve, reject) => {
            console.log('🚀 Fetching: %s - room: %s', active.file, active.room)

            fetch(active.file)
              .then(response => {
                this.cache
                  .put(active.file, response.clone())
                  .then(resolve)
                  .catch(reject)
              })
              .catch(reject)
          }
        )
      }

      queue()
        .then(() => {
          this.cacheable.shift()

          if (this.cacheable.length) {
            this.processCaching()
          }
        })
        .catch(
          error => {
            console.log('🚧 Fetching abort %s : %s', active.file, error.message)
          }
        )
    }
  }

  changePriority (room) {
    console.log('--------------------------')
    console.log('🧽 CACHING PRIORITY CHANGE')
    console.log('Room %s is now in top list', room)
    console.log('--------------------------')

    if (this.cacheable.length) {
      this.cacheable = this.cacheable
        .sort(
          a => {
            return parseInt(a.room) === parseInt(room) ? 0 : 1
          }
        )
        .filter(a => parseInt(a.room) >= parseInt(room))

      this.processCaching()
    }
  }
}

export default new Caching()
