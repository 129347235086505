import React, { useEffect, useState } from 'react'
import GameStore from '../../../stores/GameStore'
import AppUtils from '../../../helpers/AppUtils';
import ServerTime from '../../../stores/ServerTime';
import {reactLocalStorage} from 'reactjs-localstorage';
import {LS_KEYS} from '../../../conf/app-conf';

const RoomBonus = ({ getterBonus, initBonus }) => {
  const [bonusPoints, setBonusPoints] = useState(initBonus);
  const giveBonus = () => {
    return bonusPoints;
  };
  let intervalID = null;
  if (getterBonus) {
    getterBonus(giveBonus);
  }
  
  useEffect(() => {
    // If time server is not set yet we save server time
    if (!reactLocalStorage.get(LS_KEYS.REMOTE_TIME)) {
      AppUtils.saveServerTime(ServerTime.time);
    }
    
    intervalID = setInterval(() => {
      setBonusPoints((points) => {
        const bp = points - GameStore.conf.rooms.room4.nbPointsByElapsedSec;
        if (bp <= 0) {
          clearInterval(intervalID)
        }
        return bp < 0 ? 0 : bp
      })
    }, 1000);

    return () => {
      clearInterval(intervalID)
    }
  }, []);

  return (
    <div className="bonus-wrapper">
      <p className="bonus-title">Bonus</p>
      <p className="bonus-points">{ bonusPoints } <span>pts</span></p>
    </div>
  )
};

export default RoomBonus;
