import React, { useState, useEffect } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import InputForm from '../common/InputForm';
import TeamAPI from '../../services/TeamAPI';
import {LS_KEYS, MESSAGES} from '../../conf/app-conf';
import LogoLVMHCompliance from '../common/LogoLVMHCompliance';
import UserStore from '../../stores/UserStore';
import GameStore from '../../stores/GameStore';
import TeamStore from '../../stores/TeamStore';

const TeamSelection = (props) => {
  const { onTeamFound } = props;
  const [fieldValue, setFieldValue] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const fieldName = 'teamcode';
  const inputHandler = (evt) => {
    if (evt.target) {
      setFieldValue(evt.target.value);
      setEnabled(evt.target.value && evt.target.value !== '' && evt.target.value.trim() !== '');
    }
  };
  const onValidate = () => {
    setErrorMsg(null);
    TeamAPI.checkTeamCode(
      { teamcode: fieldValue },
      (gameStatus) => {
        if (onTeamFound) {
          onTeamFound(gameStatus);
        }
      },
      (message) => {
        setErrorMsg(message || MESSAGES.TEAM_WRONG_CODE);
      }
     );
  };
  
  // On mount
  useEffect(() => {
    // Clear all
    UserStore.clear();
    GameStore.clear();
    TeamStore.clear();
    reactLocalStorage.remove(LS_KEYS.REMOTE_TIME);
  }, []);
  
  return (
    <div className="team-selection-wrapper">
      <LogoLVMHCompliance />
      <div className="welcome-wrapper">
        <h1>welcome!</h1>
        <div className="teamcode-form">
          <p className="field-label">PLEASE INDICATE YOUR TEAM CODE</p>
          <InputForm
            inputHandler={ inputHandler }
            onValidate={ onValidate }
            fieldName={ fieldName }
            fieldValue={ fieldValue }
            errorMsg={ errorMsg }
            enabled={ enabled }
          />
        </div>
      </div>
    </div>
  );
};

export default TeamSelection;