import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react";
import GameHUD from './game-hud/GameHUD';
import TeamAPI from '../../services/TeamAPI';
import EndRoom from '../post-game/EndRoom';
import Room1 from './rooms/Room1';
import Room2 from './rooms/Room2';
import Room3 from './rooms/Room3';
import Room4 from './rooms/Room4';
import ModalStore from '../../stores/ModalStore';
import GlobalStore from '../../stores/GlobalStore';
import GameAPI from '../../services/GameAPI';
import Quiz from './quiz/Quiz';

const TheGame = observer((props) => {
  const { userStore, teamStore, globalStore, gameStore, onEndGame } = props;
  const dbPath = `teams/${userStore.gamecode}-${userStore.teamname}-${userStore.teamcode}`;
  const [hasTeamData, setHasTeamData] = useState(false);
  const showEndRoom = teamStore.currentRoomUnlockerPlayer || gameStore.gameStatus.status === 2;

  // On component did mount
  useEffect(async () => {
    TeamAPI.listenToTeamData(dbPath, (hasData) => {
      setHasTeamData(hasData);
    });

    // Get serverTime
    GameAPI.getServerDate().then((response) => {
      gameStore.setConf({ serverTime: response });
    });

    return () => {
      TeamAPI.stopListenToTeamData(dbPath);
    }
  }, []);

  useEffect(() => {
    if (showEndRoom && ModalStore.content !== null) {
      ModalStore.hideModal();
    }

    if (showEndRoom && GlobalStore.isLoading) {
      GlobalStore.setLoadingState(false);
    }
  });

  /*
  * after listen to team data
  * If the current has an unlocker player we show the end -rooom page
  * */
  return (
    <div className="the-game-wrapper">
      {
        hasTeamData && (
          <React.Fragment>
            {/* END ROOM */}
            {
              showEndRoom && (
                <EndRoom
                  teamStore={ teamStore }
                  globalStore={ globalStore }
                  gameStore={ gameStore }
                />
              )
            }
            {/* GAME ROOM1 - ROOM4 */}
            {
              !teamStore.currentRoomUnlockerPlayer && gameStore.gameStatus.status === 1 && teamStore.currentRoom !== 'room0' && (
                <React.Fragment>
                  <div className="room-container">
                    {
                      teamStore.currentRoom === 'room1' && <Room1 teamStore={ teamStore } />
                    }
                    {
                      teamStore.currentRoom === 'room2' && <Room2 teamStore={ teamStore } />
                    }
                    {
                      teamStore.currentRoom === 'room3' && <Room3 teamStore={ teamStore } />
                    }
                    {
                      teamStore.currentRoom === 'room4' && <Room4 teamStore={ teamStore } />
                    }
                  </div>
                  <GameHUD teamStore={ teamStore } gameStore={ gameStore } />
                </React.Fragment>
              )
            }
            {/* QUIZ */}
            {
              gameStore.gameStatus.status === 1 && teamStore.currentRoom === 'room0' && !teamStore.currentRoomUnlockerPlayer && <Quiz gameStore={ gameStore } teamStore={ teamStore } globalStore={ globalStore }/>
            }
          </React.Fragment>
        )
      }
    </div>
  );
});

export default TheGame;