import React from 'react';

const InputForm = (props) => {
  const {
    inputHandler,
    onValidate,
    fieldName,
    fieldValue,
    errorMsg,
    enabled,
    maxChars
  } = props;
  
  const onKeyDown = (evt) => {
    const key = evt.keyCode || evt.which;
    if (key === 13 || key === 10) {
      if (fieldValue.trim() !== '') {
        onValidate();
      }
    }
  };
  
  return (
    <div className="input-form-wrapper">
      <div className="input-wrapper">
        <input type="text" name={ fieldName } value={ fieldValue } onChange={ inputHandler } onKeyDown={ onKeyDown } maxLength={ maxChars } />
        <button onClick={ onValidate } disabled={ !enabled }>OK</button>
      </div>
      {
        errorMsg && <p className="message">{ errorMsg }</p>
      }
    </div>
  );
};

export default InputForm;