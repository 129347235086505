import React from 'react';
import LogoLVMH from '../../assets/images/lvmh-ethics-compliance.png';

const LogoLVMHCompliance = () => {
  return (
    <div className="logo-container">
      <img src={ LogoLVMH } alt=""/>
    </div>
  );
};

export default LogoLVMHCompliance;