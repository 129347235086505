import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import GlobalStore from '../../stores/GlobalStore';

const Help = ({ helpContent }) => {
  const rootEl = useRef(null);
  let questionsElements = null;
  let currentId = null;
  
  const onSelectQuestion = (id) => {
    let target = null;
    if (currentId !== null) {
      const target = rootEl.current.querySelector(`#question-${currentId}`);
      target.classList.remove('selected');
      target.querySelector('.response-text').style.height = 0;
    }
    if (id !== null) {
      target = rootEl.current.querySelector(`#question-${id}`);
      if (target) {
        target.querySelector('.response-text').style.height = `${target.dataset.height}px`;
        target.classList.add('selected');
        currentId = id;
      }
    } else {
      target = rootEl.current.querySelector(`#question-${currentId}`);
      if (target) {
        target.querySelector('.response-text').style.height = `${target.dataset.height}px`;
      }
    }
  };
  
  const getQuestionHTML = (id) => {
    return {
      __html: helpContent[id].question
    }
  };
  
  const getResponseHTML = (id) => {
    return {
      __html: helpContent[id].response
    }
  };
  
  // Calculates
  const calculateHeightOfResponses = () => {
    // All questions/responses block
    if (rootEl && rootEl.current) {
      questionsElements = rootEl.current.querySelectorAll('.question-item');
      if (questionsElements) {
        questionsElements.forEach((qEl) => {
          const repEl = qEl.querySelector('.response-text');
          const repElF = qEl.querySelector('.response-text-phantom');
          qEl.dataset.height = `${repElF.getBoundingClientRect().height}`;
          qEl.classList.remove('selected');
          repEl.style.height = 0;
        });
      }
    }
    currentId = null;
  };
  
  // Mounted
  useEffect(() => {
    calculateHeightOfResponses();
    rootEl.current.style.opacity = 1;
  });
  
  useEffect(() => {
    window.addEventListener('resize', calculateHeightOfResponses);
    return () => {
      window.removeEventListener('resize', calculateHeightOfResponses);
    };
  }, []);
  
  return (
    <div className="help-container">
      <h1>HELP!</h1>
      <section className="content" ref={ rootEl }>
        <div className="inner">
          {
            helpContent.map((itemObj, index) => {
              return (
                <div id={ `question-${index}` } className="question-item" key={ uuidv4() }>
                  <button onClick={ () => { onSelectQuestion(index); } }>
                    <span className="question" dangerouslySetInnerHTML={ getQuestionHTML(index) } />
                    <span className="icon" />
                  </button>
                  <p className="response-text" dangerouslySetInnerHTML={ getResponseHTML(index) } />
                  <p className="response-text-phantom" dangerouslySetInnerHTML={ getResponseHTML(index) } />
                </div>
              );
            })
          }
        </div>
      </section>
      <div className="btn-back-container">
        <button onClick={ () => { GlobalStore.hideHelp(); } }>BACK</button>
      </div>
    </div>
  );
};

export default Help;