import React from 'react';
import Timer from '../common/Timer';
import GameAPI from '../../services/GameAPI';
import LogoLVMHCompliance from '../common/LogoLVMHCompliance';

const HowToPlay = ({ gameStore, globalStore }) => {
  const { duration, status } = gameStore.gameStatus;
  const onDurationElapsed = async () => {
    await GameAPI.onDurationElapsed(globalStore,() => {
      globalStore.setCurrentPageByStatus(gameStore.gameStatus.status);
    });
  };
  
  return (
    <div className="pre-game-wrapper">
      <LogoLVMHCompliance />
      <h1>HOW TO PLAY</h1>
      {/*
       <section className="intro">
       <p>Before starting the game, you will first have to answer a small “Compliance Day” quiz, then go through three different rooms, before answering a final question that will allow you to finish the game; all in less than 45 minutes.</p>
       <p>Mini Quiz “Ethic & Compliance” → Room 1 → Room 2 → Room 3 → Final question</p>
       </section>
      */}
      <section className="explanation">
        <div className="cols">
          <div className="col">
            <h2>YOU WILL NEED</h2>
            <div className="title-underline" />
            <p>Squared paper and a pen</p>
            <h2>ABOUT THE GAME</h2>
            <div className="title-underline" />
            <p>
              You will have to visit 3 different rooms before answering a final question which will allow you to escape and end the game, in less than 45 minutes.
              <br />
              In each room, look for the right place to click, solve riddles and find the way to escape from the room and move on to the next room.
            </p>
          </div>
          <div className="col">
            <h2>HOW TO PLAY WITH YOUR TEAM</h2>
            <div className="title-underline" />
            <p>Video conferencing will allow you to discuss and share findings.If one of you finds the way to go out, the all team will move on to the next room in the same time. The objective is to play together and to win as a Team.</p>
            <h2>IF YOU NEED HELP</h2>
            <div className="title-underline" />
            <p>Use the "HELP" button : it will guide you and you will be able to see other’s questions.
              <br />
              If your team is blocked in a room, you will be given clues to help you find the solution.
              <br />
              But each clue will cost you points on your final score.
            </p>
          </div>
        </div>
        <p className="together">HAVE FUN TOGETHER!</p>
      </section>
      
      <div className="how-to-message">
        {
          status === -1 && (
            <p className="come-back-later">THE GAME WILL NOT START SOON. PLEASE COME BACK LATER</p>
          )
        }
        {
          status === 0 && (
            <React.Fragment>
              <p className="label">THE GAME START IN</p>
              <p className="timer">
                <Timer duration={ duration } onDurationElapsed={ onDurationElapsed } />
              </p>
            </React.Fragment>
          )
        }
      </div>
    </div>
  );
};

export default HowToPlay;