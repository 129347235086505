import React, { useEffect } from 'react'
import { toJS } from 'mobx';
import RootLevel from './RootLevel'
import TeamAPI from '../../../services/TeamAPI';

const Room1 = ({ teamStore }) => {
  // Check if if room'date is not set
  useEffect(() => {
    const teamData = toJS(teamStore);
    if (teamData.rooms && teamStore.rooms.room1 && !teamStore.rooms.room1.dateOpened) {
      TeamAPI.setRoomOpening({ teamCode: teamStore.teamCode, roomId: 'room1' });
    }
  }, []);

  return (
      <div className="room-game-container">
        <RootLevel room={ 1 } />
      </div>
  );
};

export default Room1;
