import { makeAutoObservable } from 'mobx';

class UserStore {
  uid = null;
  firstname = null;
  teamcode = null;
  teamname = null;
  gamecode = null;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  init = (userObj) => {
    Object.keys(userObj).forEach((key) => {
      this[key] = userObj[key];
    });
  };
  
  clear = () => {
    this.uid = null;
    this.firstname = null;
    this.teamcode = null;
    this.teamname = null;
    this.gamecode = null;
  }
}

export default new UserStore();