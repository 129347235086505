export const FIREBASE_CONF = {
  BASE_CONF_DEV: {
    apiKey: "AIzaSyDCAjy8SU3VmwM8ohxVLlj6DkGsSnoM6g0",
    authDomain: "syl-lvmh-lab.firebaseapp.com",
    databaseURL: "https://syl-lvmh-lab-dev.firebaseio.com",
    projectId: "syl-lvmh-lab",
    storageBucket: "syl-lvmh-lab.appspot.com",
    messagingSenderId: "246053053976",
    appId: "1:246053053976:web:6612ac2278f7ae178b856d",
    measurementId: "G-7BP1CJHR1G"
  },
  BASE_CONF_PROD: {
    apiKey: "AIzaSyBTprjjxmOYfoBOHWXWZP7IKXWDkrWf9Xc",
    authDomain: "tds-lvmhcomplianceescape.firebaseapp.com",
    databaseURL: "https://tds-lvmhcomplianceescape.firebaseio.com",
    projectId: "tds-lvmhcomplianceescape",
    storageBucket: "tds-lvmhcomplianceescape.appspot.com",
    messagingSenderId: "268524107351",
    appId: "1:268524107351:web:ab51bcb91dc414fa855512",
    measurementId: "G-PPK22YBN8J"
  }
};

export const APP = {
  NAME: 'LVMH - ESCAPE GAME',
  NB_ROOMS: 4,
  USERNAME_MAX_CHARS: 20
};

export const PAGES = {
  TEAM_SELECT: {
    id: 0,
    name: 'team-selection'
  },
  USERNAME: {
    id: 1,
    name: 'username-entry'
  },
  HOW_TO_PLAY: {
    id: 11,
    name: 'how-to-play'
  },
  GAME: {
    id: 2,
    name: 'game-inside'
  },
  END_ROOM: {
    id: 3,
    name: 'pre-hof'
  },
  HALL_OF_FAME: {
    id: 4,
    name: 'hall-of-fame'
  }
};

export const LS_KEYS = {
  REMOTE_TIME: 'lvmh-escape-game-remote-time' // server time in ms
};

export const HELP_CONTENTS = [
  {
    question: 'What do I have to do?',
    response: 'In every room, look for the hidden riddles that lead to the solution and allow you to access the next room. Each player is free to go wherever they want, independently of the others. You can talk to the other players via video conferencing.'
  },
  {
    question: 'What should I do in the first room, the hall?',
    response: 'It was a good idea to look here ;)<br />In this room, you have to find the right order and the colors that will help you find the code to dial. Remember that some riddles are useless and will waste your time.'
  },
  {
    question: 'What should I do in the second room: the gallery?',
    response: 'Another clue for you ;)<br />In this room, the paintings will help you find where to click in the master painting. Be careful, some paintings are useless and will waste your time.'
  },
  {
    question: 'What should I do in the third room: the passageway?',
    response: 'It was really smart of you to look here ;)<br />In this room, you have to solve all the riddles to find a directional combination (e.g: down-down-up-left-right-up).'
  },
  {
    question: 'How do you move from one room to another?',
    response: 'If one of your friends finds the solution and, for example, dials the correct code for the first room; all team-mates will move to the next room at the same time.'
  },
  {
    question: 'What can we do if we get stuck?',
    response: 'If your team is blocked, you will be able to unlock the clues using the menu on the right.<br />To do this, a majority of players need to click on the clue button. These clues will cost you points on your final score.'
  },
  {
    question: 'How much time do we have?',
    response: 'You have 45 minutes to finish the escape game (a short quiz, three rooms and a final question).'
  },
  {
    question: 'What do we need for the escape game?',
    response: 'Take graph paper (if possible) and a pencil or pen.<br />The escape game is played on a desktop computer (not on a smartphone or tablet).'
  }
];

export const LABELS = {
  CANCEL: 'CANCEL',
  VALIDATE: 'VALIDATE',
  YES: 'YES',
  NO: 'NO',
  OK: 'OK',
  NOT_YET: 'NOT YET'
};

/* -1: before start-up, 0: start-up, 1: started, 2: ended */
export const GAME_STATUS = {
  COMING: -1,
  TO_START: 0,
  STARTED: 1,
  ENDED: 2
};

export const MESSAGES = {
  TEAM_WRONG_CODE: 'Wrong code, try again please',
  GAME_ALREADY_ENDED: 'The game is already ended, please choose another code',
  TEAM_NOT_FOUND: 'Team not found',
  FIRST_NAME_TAKEN: 'First name already taken, please choose another one',
  INTERNAL_ERROR: 'Internal error, try again please',
  ASK_CLUE: 'Do you really want to see this clue?',
  CLUE_ALREADY_ASKED: 'You have already requested this clue',
  CLUE_OUT_OF_BUDGET: 'You don\'t have enough points to buy this clue',
  UNLOCKER_1: 'found the correct code',
  UNLOCKER_2: 'found the solution',
  SOLUTIONS: {
    room1: 'Wrong code, try again please',
    room2: 'Bad combination, try again please',
    room3: '',
    room4: 'Wrong answer, try again please'
  },
  END_ROOM: {
    room0: 'You\'re entering<br />the game',
    room1: 'Easy One!',
    room2: 'Well done!',
    room3: 'You\'re good!',
    room4: 'CONGRATULATIONS!<br />YOU WENT OUT',
    lose: 'Sorry, You lose'
  },
  STILL_IN_GAME: 'Still playing',
  OUT_OF_TIME: 'Out of time',
  SIGN_OUT: 'You are about to leave the game, are you sure?',
  QUIZ_WRONG: 'One of your answers is wrong',
  CONNEXION_LOST: 'Connection lost. Please check your internet connection'
};

export const ERROR_CODES = {
  INVALID_ARGUMENT: 'invalid-argument',
  NOT_FOUND: 'not-found',
  ALREADY_EXISTS: 'already-exists'
};

export const QUIZ_RESPONSE_LAYOUT = {
  LINEAR: 'linear',
  COLUMN: 'column'
};

export const QUIZ = [
  {
    id: 0,
    question: '1.What is the address of the LVMH internal alert system?',
    responses: [
      {
        response: 'https://businesskeeper.lvmh.com',
        correct: false
      },
      {
        response: 'ethics@lvmh.fr',
        correct: false
      },
      {
        response: 'https://alertline.lvmh.com',
        correct: true
      },
      {
        response: 'https://navexglobal.lvmh.com',
        correct: false
      }
    ],
    hasAnswered: false,
    hasCorrectAnswer: false,
    layout: QUIZ_RESPONSE_LAYOUT.LINEAR
  },
  {
    id: 1,
    question: '2. Which of the following statements is NOT consistent with the LVMH Internal guidelines on gifts & invitations ?',
    responses: [
      {
        response: 'Monetary or other cash equivalent gifts are strictly prohibited',
        correct: false
      },
      {
        response: 'Any gifts to public officials must be approved by Legal and/or Compliance',
        correct: false
      },
      {
        response: 'Employees should discourage suppliers and partners from giving them gifts',
        correct: false
      },
      {
        response: 'If I cannot offer a gift to a business partner in the name of LVMH, I may still offer it if I pay for it personally',
        correct: true
      }
    ],
    hasAnswered: false,
    hasCorrectAnswer: false,
    layout: QUIZ_RESPONSE_LAYOUT.COLUMN
  },
  {
    id: 2,
    question: '3. A competitive bidding process is organized to select a consulting firm. An internal jury has been organized to select the service provider, and Jane Doe is a part of it. Jane retained an interest in a firm in which she used to be a partner. The CEO of this firm asks her for information on the call for tenders. What should Jane do?',
    responses: [
      {
        response: 'Disclose the situation to the purchasing manager, and take part in the jury vote',
        correct: false
      },
      {
        response: 'Provide information to the manager of the consulting firm',
        correct: false
      },
      {
        response: 'Notify the purchasing manager of the request made to her and withdraw from the jury',
        correct: true
      },
      {
        response: 'Divest from the consulting firm immediately',
        correct: false
      }
    ],
    hasAnswered: false,
    hasCorrectAnswer: false,
    layout: QUIZ_RESPONSE_LAYOUT.COLUMN
  }
];

export const KEYPAD_FUNC = {
  NUMBER: 'number',
  SUPPR: 'suppr',
  VALIDATE: 'validate',
  HIDDEN: 'hidden',
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right'
};

export const NUMPAD_KEYS = [
  {
    label: 1,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 2,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 3,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 4,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 5,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 6,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 7,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 8,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 9,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.SUPPR
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.NUMBER
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.VALIDATE
  }
];

export const DIRECTION_PAD_KEYS = [
  {
    label: 1,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 2,
    funcType: KEYPAD_FUNC.UP
  },
  {
    label: 3,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 4,
    funcType: KEYPAD_FUNC.LEFT
  },
  {
    label: 5,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 6,
    funcType: KEYPAD_FUNC.RIGHT
  },
  {
    label: 7,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 8,
    funcType: KEYPAD_FUNC.DOWN
  },
  {
    label: 9,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.SUPPR
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.HIDDEN
  },
  {
    label: 0,
    funcType: KEYPAD_FUNC.VALIDATE
  }
];