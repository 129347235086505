import { makeAutoObservable, toJS } from 'mobx';
import { APP } from '../conf/app-conf';

class TeamStore {
  teamCode = null;
  score = false;
  currentRoom = null;
  players = null;
  teamName = null;
  rooms = {};
  
  constructor() {
    // Init rooms
    for (let i = 1; i <= APP.NB_ROOMS; i++) {
      this.rooms[`room${i}`] = {
        status: 0,
        unlockerPlayer: null,
        clue1: {
          status: 0,
          voters: null
        },
        clue2: {
          status: 0,
          voters:  null
        },
        clue3: {
          status: 0,
          voters:  null
        },
        clue4: {
          status: 0,
          voters:  null
        }
      }
    }
    makeAutoObservable(this, {
      teamCode: false,
      setTeamCode: false,
      teamName: false,
      setTeamName: false,
    } )
  }
  
  init = (dataObj) => {
    Object.keys(dataObj).forEach((key) => {
      if (key !== 'current-room' && 'players' && 'name' && 'code') {
        this[key] = dataObj[key];
      }
    });
    this.currentRoom = dataObj['current-room'];
    if (dataObj.players) {
      this.players = [];
      for (const key in dataObj.players) {
        this.players.push({
          id: key,
          ...dataObj.players[key]
        })
      }
    }
  };
  
  clear = () => {
    this.teamCode = null;
    this.score = false;
    this.currentRoom = null;
    this.players = null;
    this.teamName = null;
    this.rooms = {};
  };
  
  setTeamCode = (code) => {
    this.teamCode = code;
  };
  
  setTeamName = (name) => {
    this.teamName = name;
  };
  
  setScore = (point) => {
    this.score = point;
  };
  
  setCurrentRoom = (room) => {
    this.currentRoom = room;
  };
  
  setPlayers = (members) => {
    this.players = members;
  };
  
  setRooms = (rooms) => {
    this.rooms = rooms;
  };
  
  get currentRoomData() {
    // console.log('this.rooms && this.currentRoom: ', this.rooms, this.currentRoom);
    if (this.rooms && this.currentRoom) {
      return this.rooms[this.currentRoom];
    }
    return {};
  }
  
  get currentRoomUnlockerPlayer() {
    const data = toJS(this.currentRoomData);

    if (data && data['unlocker-player']) {
      const uid = Object.keys(data['unlocker-player'])[0];
      return this.players.find((p) => {
        return p.id === uid;
      });
    }
    
    return null;
  }
  
  get roomsList() {
    const nbRooms = Object.entries(this.rooms).length;
    const result = [];
    let currentRoomNumber = null;
    let nextRoom = null;
    try {
      currentRoomNumber = parseInt(this.currentRoom.substr(4), 10);
      if (toJS(this.rooms[`room${currentRoomNumber + 1}`]) !== null) {
        nextRoom = currentRoomNumber + 1;
      }
    } catch (e) {
      console.log('currentRoomNumber, error: ', e);
    }
    for (let i = 1; i <= nbRooms; i++) {
      const tmp = this.rooms[`room${i}`];
      if (tmp) {
        result.push({
          number: i === 4 ? '?' : i,
          status: tmp.status,
          isNext: nextRoom === i
        });
      }
    }
    return result;
  }
}

export default new TeamStore();