import { makeAutoObservable } from 'mobx';

class GamePlayStore {
  numPadReset = false;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  init = (gameObj) => {
    Object.keys(gameObj).forEach((key) => {
      this[key] = gameObj[key];
    });
  };
  
  setNumPadRefresh = (state) => {
    this.numPadReset = state;
  };
}

export default new GamePlayStore();