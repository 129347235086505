import React, { useState } from 'react';
import {KEYPAD_FUNC} from '../../conf/app-conf';

const KeyPad = ({ label, funcType, onClickKeyPad }) => {
  const onPress = () => {
    onClickKeyPad(label, funcType)
  };
  return (
    <button className={ `keypad-btn ${funcType}`} onClick={ onPress } disabled={funcType === KEYPAD_FUNC.HIDDEN}><div><span>{ label }</span></div></button>
  );
};

export default KeyPad;
