import React from 'react';
import { observer } from "mobx-react";
import BtnClose from '../../assets/images/close-btn.svg';

const ModalWrapper = observer(({ modalStore }) => {
  const onClose = () => {
    if (modalStore.onClose) {
      modalStore.onClose();
    }
    modalStore.hideModal();
  };
  const onCancel = () => {
    if (modalStore.onCancel) {
      modalStore.onCancel();
    }
    modalStore.hideModal();
  };
  const onValidate = () => {
    if (modalStore.onValidate) {
      modalStore.onValidate();
    }
    modalStore.hideModal();
  };
  
  return (
    <div className={ modalStore.content === null ? 'modal-wrapper' : 'modal-wrapper modal-open' }>
      <div className="modal-inner">
        <button className="btn-close" onClick={ onClose }>
          <img src={ BtnClose } alt="" />
        </button>
        <div className="modal-content-container">
          { modalStore.content }
        </div>
        {
          (modalStore.cancelLabel || modalStore.validateLabel) && (
            <div className="buttons-wrapper">
              {
                modalStore.validateLabel && <button className="button-validate" onClick={ onValidate }><span>{ modalStore.validateLabel }</span></button>
              }
              {
                modalStore.cancelLabel && <button className="button-cancel" onClick={ onCancel }><span>{ modalStore.cancelLabel }</span></button>
              }
            </div>
          )
        }
      </div>
    </div>
  );
});

export default ModalWrapper;