import React, { useState } from 'react';
import InputForm from '../common/InputForm';
import TeamStore from '../../stores/TeamStore';
import TeamAPI from '../../services/TeamAPI';
import { MESSAGES, APP } from '../../conf/app-conf';
import LogoLVMHCompliance from '../common/LogoLVMHCompliance';

const UsernameDefinition = (props) => {
  const { onDefined, gameStore } = props;
  const [fieldValue, setFieldValue] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const fieldName = 'username';
  const teamName = `the ${TeamStore.teamName} team`;
  
  const inputHandler = (evt) => {
    if (evt.target) {
      setFieldValue(evt.target.value);
      setEnabled(evt.target.value && evt.target.value !== '' && evt.target.value.trim() !== '');
    }
  };
  
  const onValidate = () => {
    setErrorMsg(null);
    TeamAPI.addPlayer(
      {
        firstname: fieldValue,
        code: TeamStore.teamCode
      },
      (data) => {
        if (onDefined) {
          onDefined(gameStore.gameStatus.status);
        }
      },
      (errorObj) => {
        if (errorObj.details) {
          setErrorMsg(errorObj.details);
        } else {
          setErrorMsg(MESSAGES.INTERNAL_ERROR);
        }
      }
    );
  };
  
  return (
    <div className="username-definition-wrapper">
      <LogoLVMHCompliance />
      <div className="welcome-wrapper">
        <h1>YOU ARE PART OF <span>{ teamName }</span></h1>
        <div className="username-form">
          <p>PLEASE INDICATE YOUR FIRST NAME</p>
          <InputForm
            inputHandler={ inputHandler }
            onValidate={ onValidate }
            fieldName={ fieldName }
            fieldValue={ fieldValue }
            errorMsg={ errorMsg }
            enabled={ enabled }
            maxChars={ APP.USERNAME_MAX_CHARS }
          />
        </div>
      </div>
    </div>
  );
};

export default UsernameDefinition;