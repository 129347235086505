import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const RoomProgress = (props) => {
  const { cssTheme, rooms } = props;
  const getCssRoom = (roomStatus, isNext) => {
    let css = isNext ? 'is-next ' : '';
    switch (roomStatus) {
      case 0:
        return `${css}room-closed`;
      case 1:
        return `${css}room-opened`;
      default:
        return `${css}room-resolved`;
    }
  };
  return (
    <div className={ cssTheme }>
      <ul>
        {
          rooms.map((roomObj) => {
            return (
              <li className={ getCssRoom(roomObj.status, roomObj.isNext) } key={ uuidv4() }>
                <span>{ roomObj.number }</span>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

export default RoomProgress;