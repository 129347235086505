class ServerTime {
  constructor() {
    let timeMS = null;
    let intervalID = null;
    
    const startTick = () => {
      intervalID = setInterval(() => {
        timeMS += 1000;
        // document.querySelector('.date-server ').innerHTML = new Date(timeMS).toString() + '  ' + timeMS;
      }, 1000);
    };
    
    this.setTime = (ms) => {
      this.stopTime();
      timeMS = Math.floor(parseInt(ms, 10) / 1000) * 1000;
      startTick();
    };
    
    this.getTime = () => {
      return timeMS;
    };
    
    this.stopTime = () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }
  
  get time() {
    return this.getTime();
  }
}

export default new ServerTime();