import React, { useState } from 'react'
import RootLevel from './RootLevel'
import GlobalStore from '../../../stores/GlobalStore'
import GameAPI from '../../../services/GameAPI'

const Room2 = ({ teamStore }) => {
  const [setErrorMsg] = useState(null);
  const checkSolution = async () => {
    GlobalStore.setLoadingState(true);

    try {
      await GameAPI.validateSolution('4575', teamStore.teamCode, 'room2');
    } catch (errorObj) {
      if (errorObj.details) {
        setErrorMsg(errorObj.details);
      }
    }

    GlobalStore.setLoadingState(false);
  };

  return (
    <div className="room-game-container">
      <RootLevel room={ 2 } onFindRoom2={ checkSolution } />
    </div>
  );
};

export default Room2;
