import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  DIRECTION_PAD_KEYS,
  KEYPAD_FUNC,
  MESSAGES,
  NUMPAD_KEYS,
} from '../../../../conf/app-conf';
import KeyPad from '../../../common/KeyPad';
import GlobalStore from '../../../../stores/GlobalStore';
import GameAPI from '../../../../services/GameAPI';
import TeamStore from '../../../../stores/TeamStore';

const NumPad = observer(({ gamePlayStore, padType, roomId }) => {
  let cssState = 'input-state';
  const [errorMsg, setErrorMsg] = useState(null);
  const [currentCode, setCurrentCode] = useState('');
  
  console.log('gamePlayStore.numPadReset:= ', gamePlayStore.numPadReset);
  useEffect(() => {
    if (gamePlayStore.numPadReset) {
      setCurrentCode('');
      gamePlayStore.setNumPadRefresh(false);
    }
  });
  
  const checkSolution = async (code) => {
    GlobalStore.setLoadingState(true);
    try {
      const result = await GameAPI.validateSolution(code, TeamStore.teamCode, roomId);
      if (!result.isCorrect) {
        setErrorMsg(MESSAGES.SOLUTIONS.room1);
      }
    } catch (errorObj) {
      if (errorObj.details) {
        setErrorMsg(errorObj.details);
      }
    }
    GlobalStore.setLoadingState(false);
  };
  
  const onPressKey = (keyValue, keyFunc) => {
    setErrorMsg(null);
    switch (keyFunc) {
      case KEYPAD_FUNC.NUMBER:
      case KEYPAD_FUNC.UP:
      case KEYPAD_FUNC.DOWN:
      case KEYPAD_FUNC.LEFT:
      case KEYPAD_FUNC.RIGHT:
        setCurrentCode((old) => {
          return `${old}${keyValue}`;
        });
        break;
      case KEYPAD_FUNC.SUPPR:
        setCurrentCode((old) => {
          return old.substring(0, old.length - 1);
        });
        break;
      default:
        if (currentCode.length > 0) {
          checkSolution(currentCode);
        }
        break;
    }
  };
  
  if (currentCode.length === 0) {
    cssState = 'input-state';
  } else {
    if (errorMsg !== null) {
      cssState = 'input-state input-state-wrong';
    } else {
      cssState = 'input-state input-state-correct';
    }
  }
  
  let keysPad = [];
  if (padType === 'num') {
    keysPad = _.chunk(NUMPAD_KEYS.map((keyItem) => {
      return {
        ...keyItem,
        onPressKey
      }
    }), 3);
  } else {
    keysPad = _.chunk(DIRECTION_PAD_KEYS.map((keyItem) => {
      return {
        ...keyItem,
        onPressKey
      }
    }), 3);
  }
  
  return (
    <div className="numpad-container">
      <div className="numpad-inner">
        <h1>Enter the code</h1>
        <section className={ cssState }>
          <div className="digits-container">
            {
              currentCode.length > 0 && currentCode.split('').map((letter) => {
                return <div className="code-state-indicator" key={  uuidv4() } />
              })
            }
            {
              currentCode.length === 0 && <div className="code-state-indicator placeholder" key={  uuidv4() } />
            }
          </div>
          {
            <p className="message">{ errorMsg }</p>
          }
        </section>
        <section className="numpad-keys-wrapper">
          {
            keysPad.map((triple) => {
              return (
                <div className="padline" key={ uuidv4() }>
                  {
                    triple.map((keyObj) => {
                      return <KeyPad funcType={ keyObj.funcType } label={ keyObj.label } onClickKeyPad={ onPressKey } key={ uuidv4() } />;
                    })
                  }
                </div>
              )
            })
          }
        </section>
      </div>
    </div>
  );
});

export default NumPad;