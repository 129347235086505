import { FirebaseFunctions, FirebaseAuth, FirebaseDB } from './FirebaseAPI';
import UserStore from '../stores/UserStore';
import GlobalStore from '../stores/GlobalStore';

class UserAPI {
  constructor() {
    const addUser = FirebaseFunctions.httpsCallable('addUser');
    
    // Firebase login
    this.signIn = () => {
      // Sign in anonymously
      return FirebaseAuth.signInAnonymously();
    };
    
    // Firebase log out
    this.signOut = () => {
      return FirebaseAuth.signOut();
    };
    
    // Get player by uid
    this.getPlayerById = async (uid) => {
      let userData = null;
      try {
        userData = await FirebaseDB.ref(`/players/${uid}`).once('value');
        userData = userData.val();
      } catch (errorObj) {
        console.log('getPlayerById errorObj --> ', errorObj);
      }
      return userData;
    };
    
    // Login
    this.doLogin = (onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      this.signIn().then((response) => {
        console.log('user credential: ', response);
        UserStore.init({
          uid: response.user.uid
        });
        onSuccess(response.user);
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
  }
}

export default new UserAPI();