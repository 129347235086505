import { reactLocalStorage } from 'reactjs-localstorage';
import { LS_KEYS } from '../conf/app-conf'

class AppUtils {
  constructor() {
    this.formatNumber = (num) => {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    };
    
    /**
     * @param { number } defaultPoints
     * @param { number } serverTime - in milliseconds
     * @return { number } - bonus point calculate based on current time or the points passed in params
     * */
    this.getBonusPoints = (defaultPoints, serverTime) => {
      const value = reactLocalStorage.get(LS_KEYS.REMOTE_TIME);
      if (value) {
        const diff = serverTime - parseInt(value, 10);
        const diffInSecond = Math.round(diff/ 1000);
        if (diffInSecond > 0) {
          console.log('diffInSecond --> ', diffInSecond);
          const totalBonus = defaultPoints - (diffInSecond * 100);
          return totalBonus > 0 ?  totalBonus : 0;
        }
      }
      return defaultPoints;
    };
    
    /**
     * Save server time for bonus calculation no refresh
     * @param { number } serverTime - in milliseconds
     * */
    this.saveServerTime = (serverTime) => {
      reactLocalStorage.set(LS_KEYS.REMOTE_TIME, serverTime);
    };
  }
}

export default new AppUtils();