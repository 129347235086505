import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import QuizItemResponse from './QuizItemResponse';
import { QUIZ_RESPONSE_LAYOUT } from '../../../conf/app-conf';

const QuizItem = ({ quizItemObj }) => {
  const onSelect = (indexRep, isCorrectAnswer) => {
    console.log('onSelect -> ', indexRep, isCorrectAnswer);
    quizItemObj.onQuestionAnswered(quizItemObj.id, isCorrectAnswer, indexRep);
  };
  const responsesTwoByTwo = _.chunk(quizItemObj.responses, 2);
  
  return (
    <div className="quiz-item-wrapper">
      <h1>{ quizItemObj.question }</h1>
      <div className="title-underline" />
      <div className={ `responses-wrapper ${quizItemObj.layout}` }>
        {
          quizItemObj.layout === QUIZ_RESPONSE_LAYOUT.LINEAR && quizItemObj.responses.map((responseItem) => {
            const props = {
              ...responseItem,
              onSelect
            };
            return <QuizItemResponse  { ...props } key={ uuidv4() } />;
          })
        }
        {
          quizItemObj.layout === QUIZ_RESPONSE_LAYOUT.COLUMN && (
            <React.Fragment>
              <div className="col">
                {
                  responsesTwoByTwo.map((tupleResp) => {
                    const props = {
                      ...tupleResp[0],
                      onSelect
                    };
                    return <QuizItemResponse  { ...props } key={ uuidv4() } />;
                  })
                }
              </div>
              <div className="col">
                {
                  responsesTwoByTwo.map((tupleResp) => {
                    if (tupleResp[1]) {
                      const props = {
                        ...tupleResp[1],
                        onSelect
                      };
                      return <QuizItemResponse  { ...props } key={ uuidv4() } />;
                    }
                    return null;
                  })
                }
              </div>
            </React.Fragment>
          )
        }
      </div>
    </div>
  );
};

export default QuizItem;