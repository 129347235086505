import React, { useState, useEffect } from 'react';
import Rollbar from "rollbar";
import { observer } from "mobx-react";
import { PAGES, HELP_CONTENTS, GAME_STATUS } from '../conf/app-conf';
import LoaderContainer from './common/LoaderContainer';
import TeamSelection from './team-selection/TeamSelection';
import UsernameDefinition from './username-definition/UsernameDefinition';
import UserStore from '../stores/UserStore';
import UserAPI from '../services/UserAPI';
import TheGame from './thegame/TheGame';
import TeamAPI from '../services/TeamAPI';
import TeamStore from '../stores/TeamStore';
import GameStore from '../stores/GameStore';
import GameAPI from '../services/GameAPI';
import HowToPlay from './pre-game/HowToPlay';
import ModalWrapper from './common/ModalWrapper';
import ModalStore from '../stores/ModalStore';
import HallOfFame from './post-game/HallOfFame';
import caching from '../helpers/Caching';
import Help from './common/Help';
import ToastConnexion from './common/ToastConnexion';

const App = observer((props) => {
  const { globalStore, teamStore } = props;
  const [rollbar] = useState(new Rollbar({
    accessToken: "cffddf820a6446278b424595f0ae3383",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: "production"
    }
  }));

  // Sign in anonymously first
  // Then get user detail in realtime db by uid
  // If we have registerer user we take the user to game if 'good' user: have team
  // Else invite him to enter into team members
  useEffect(() => {
    caching.start()

    globalStore.setLoadingState(true);
  
    // Firebase connexion state detection
    GameAPI.listenToFirebaseConnexionState();
    
    UserAPI.signIn().then(async (response) => {
      if (response && response.user) {
        const confObj = await GameAPI.getGameConf();
        const date = await GameAPI.getServerDate();

        // Conf
        GameStore.setConf(confObj);

        // User detail
        const userDetail = await UserAPI.getPlayerById(response.user.uid.toLowerCase());
        if (userDetail) {
          // Add user to his team
          delete userDetail.gamecode;

          TeamAPI.signIn(userDetail, (response) => {
            // Update time server
            globalStore.setCurrentPageByStatus(GameStore.gameStatus.status);
          }, (errorObj) => {
            globalStore.setCurrentPage(PAGES.TEAM_SELECT);
            console.error('errorObj --> ', errorObj);
          })
        } else {
          globalStore.setLoadingState(false);
          globalStore.setCurrentPage(PAGES.TEAM_SELECT);
        }
      }
    }).catch(() => {
      globalStore.setLoadingState(false);
      globalStore.setCurrentPage(PAGES.TEAM_SELECT);
    });
  }, []);

  if (globalStore.isLoading) {
    document.body.classList.add('loading')
  } else {
    document.body.classList.remove('loading')
  }

  // Call when user has defined his name
  const onFirstName = (gameStatus) => {
    globalStore.setCurrentPageByStatus(gameStatus);
  };
  
  // Call when team is found
  const onTeamFound = (gameStatus) => {
    if (gameStatus === GAME_STATUS.COMING) {
      globalStore.setCurrentPage(PAGES.HOW_TO_PLAY);
    } else {
      globalStore.setCurrentPage(PAGES.USERNAME);
    }
  };

  return (
    <div className="app-wrapper">
      { globalStore.currentPage.id === PAGES.TEAM_SELECT.id && <TeamSelection onTeamFound={ onTeamFound } /> }
      { globalStore.currentPage.id === PAGES.USERNAME.id && <UsernameDefinition onDefined={ onFirstName } gameStore={ GameStore } /> }
      { globalStore.currentPage.id === PAGES.GAME.id && <TheGame userStore={ UserStore } globalStore={ globalStore } teamStore={ TeamStore } gameStore={ GameStore } onEndGame={ () => {} } /> }
      { globalStore.currentPage.id === PAGES.HOW_TO_PLAY.id && <HowToPlay gameStore={ GameStore } globalStore={ globalStore } /> }
      { globalStore.currentPage.id === PAGES.HALL_OF_FAME.id && <HallOfFame gameStore={ GameStore } teamStore={ TeamStore } /> }
      { globalStore.currentPage.id === PAGES.HALL_OF_FAME.id && <HallOfFame gameStore={ GameStore } teamStore={ TeamStore } /> }
      { globalStore.isHelp && <Help helpContent={ HELP_CONTENTS.map((item) => { return { ...item, selected: false }; }) } /> }
      { globalStore.isLoading && <LoaderContainer /> }
      { !globalStore.isConnected && <ToastConnexion /> }
      <ModalWrapper modalStore={ ModalStore }/>
    </div>
  );
});

export default App;
