import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import GameAPI from '../../services/GameAPI';
import {MESSAGES} from '../../conf/app-conf';
import AppUtils from '../../helpers/AppUtils';
import SignOutButton from '../common/SignOutButton';
import Timer from '../common/Timer';

const HallOfFame = observer(({ gameStore, teamStore }) => {
  const { status, duration } = gameStore.gameStatus;
  
  // Write msg
  useEffect(() => {
    GameAPI.listenToHallOfFame();
    
    return () => {
      GameAPI.stopListenToHallOfFame();
    }
  }, []);
  
  /* When out of time, we pull off data manually
  * */
  const onDurationElapsed = () => {
    setTimeout(() => {
      GameAPI.getHofData();
    }, 1000);
  };
  
  return (
    <div className="hall-of-fame-container">
      <div className="hof-inner">
        <div className="title-wrapper">
          <h1>HALL OF  FAME</h1>
        </div>
        <div className="rank-header">
          <table>
            <thead>
            <tr>
              <th className="rank">RANK</th>
              <th className="name">TEAM NAME</th>
              <th className="score">SCORE</th>
              <th className="grank">GLOBAL RANK</th>
            </tr>
            </thead>
          </table>
        </div>
        <div className="rank-wrapper">
          <table>
            <tbody>
            {
              gameStore.partyRankin.map((teamObj, index) => {
                let cssLine = '';
                let score = `${teamObj.score} pts`;
                let globalRanking = `#${teamObj.globalRanking}`;
                
                // Everybody
                if (status !== 2) {
                  // not me
                  if (teamObj.teamcode !== teamStore.teamCode) {
                    // but has score  == 0
                    if (teamObj.score === 0) {
                      score = MESSAGES.STILL_IN_GAME;
                      globalRanking = '';
                    }
                  }
                } else {
                  if (teamObj.score === 0) {
                    score = MESSAGES.OUT_OF_TIME;
                    globalRanking = '';
                  }
                }
                
                if(status === 2 && teamObj.score === 0) {
                  cssLine = 'loser-line'
                }
                
                if (teamObj.teamcode === teamStore.teamCode) {
                  cssLine += ' the-team'
                }
                
                return (
                  <tr key={ uuidv4() } className={ cssLine }>
                    <td className="rank">{ `#${teamObj.ranking}` }</td>
                    <td className="name">{ teamObj.teamname }</td>
                    <td className="score">
                      { AppUtils.formatNumber(score) }
                    </td>
                    <td className="grank">{ globalRanking }</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
      {
        status !== 2 && <Timer duration={ duration } onDurationElapsed={ onDurationElapsed } isHidden={ true } />
      }
      <SignOutButton />
    </div>
  );
});

export default HallOfFame;