import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './components/App';
import GlobalStore from './stores/GlobalStore';
import TeamStore from './stores/TeamStore';

// Key pads
import PixClearOff from './assets/images/icon-clear-off.svg';
import PixClearOn from './assets/images/icon-clear-on.svg';
import PixValidateOff from './assets/images/icon-validate-off.svg';
import PixValidateOn from './assets/images/icon-validate-on.svg';
// Quiz
import PixSelectOff from './assets/images/icon-select-off.svg';
import PixSelectOn from './assets/images/icon-select-on.svg';
// HUD
import PixClueLocked from './assets/images/padlock-locked.svg';
import PixClueOpened from './assets/images/padlock-open.svg';
// Sign out
import PixSignOutDark from './assets/images/sign-out-dark.svg';
import PixSignOutLight from './assets/images/sign-out-light.svg';


// Preload images
const images = [
  PixClearOff,
  PixClearOn,
  PixValidateOff,
  PixValidateOn,
  PixSelectOff,
  PixSelectOn,
  PixClueLocked,
  PixClueOpened,
  PixSignOutDark,
  PixSignOutLight
];
const loader = images.map((imgContent) => {
  return new Image().src = imgContent;
});


ReactDOM.render(
  <React.StrictMode>
    <App globalStore={ GlobalStore } teamStore={ TeamStore } />
  </React.StrictMode>,
  document.getElementById('root')
);
