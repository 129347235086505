import { FirebaseDB, FirebaseFunctions } from './FirebaseAPI';
import GameStore from '../stores/GameStore';
import GlobalStore from '../stores/GlobalStore';
import ServerTime from '../stores/ServerTime';
import ConnChecker from '../assets/images/connchecker.svg';

class GameAPI {
  constructor() {
    const serverDateFunc = FirebaseFunctions.httpsCallable('getServerTime');
    const checkRoomSolutionFunc = FirebaseFunctions.httpsCallable('checkSolution');
    let hallOfFameRef = null;
    let firstCheck = false;
    
    /**
     * Get server date
     * */
    this.getServerDate = () => {
      return new Promise((resolve, reject) => {
        serverDateFunc().then((response) => {
          ServerTime.setTime(response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    };
    
    /**
     * Get Game conf
     * */
    this.getGameConf = () => {
      return new Promise(async (resolve) => {
        const confRef = FirebaseDB.ref('/config');
        if (confRef) {
          try {
            const dataSnapshot = await confRef.once('value');
            resolve(dataSnapshot.val());
          } catch (e) {
            resolve(null);
          }
        }
      });
    };
    
    /**
     * When a duration in game is elapsed
     * */
    this.onDurationElapsed = async (globalStore, onSuccess) => {
      let serverTime = ServerTime.getTime();

      // 1- Show loading first
      globalStore.setLoadingState(true);
      // 2- Get server time
      try {
        serverTime = await this.getServerDate();
        ServerTime.setTime(serverTime);
      } catch (e) {
        console.log('onDurationElapsed > getServerDate error: ', e);
      }
      
      globalStore.setLoadingState(false);
      if (onSuccess) {
        onSuccess()
      }
    };
    
    /**
     * Check solution
     * */
    this.validateSolution = (solution, teamCode, roomId, bonusPoints) => {
      return new Promise((resolve, reject) => {
        checkRoomSolutionFunc({
          solution,
          roomId,
          teamCode,
          bonusPoints: bonusPoints === undefined ? 0 : bonusPoints
        }).then((response) => {
          GlobalStore.setConnexion(true);
          if (response.data && response.data.code) {
            reject(response.data);
          } else {
            resolve(response.data);
          }
        }).catch((errorObj) => {
          console.error('validateSolution: errorObj --> ', errorObj);
          reject(errorObj);
        });
      });
    };
    
    /**
     * Get hof data
     * */
    this.getHofData = () => {
      hallOfFameRef = FirebaseDB.ref('/halloffame');
      hallOfFameRef.once('value').then((dataSnapshot) => {
        GlobalStore.setConnexion(true);
        GameStore.setHallOfFame(dataSnapshot.val());
      });
    };
    
    /**
     * Start listen to Hall of fame data
     * */
    this.listenToHallOfFame = () => {
      hallOfFameRef = FirebaseDB.ref('/halloffame');
      hallOfFameRef.on('value', async (dataSnapshot) => {
        let serverTime = await this.getServerDate();
        ServerTime.setTime(serverTime);
        GameStore.setHallOfFame(dataSnapshot.val());
      });
    };
    
    /**
     * Stoop listening to Hall of fame data
     * */
    this.stopListenToHallOfFame = () => {
      if (hallOfFameRef) {
        hallOfFameRef.off('value');
      }
    };
    
    /**
     * Start listen for connexion state
     * */
    const checkOnlineStatus = async () => {
      try {
        const online = await fetch(`${ConnChecker}?${Math.random() * 2000000000}`);
        return online.status >= 200 && online.status < 300; // either true or false
      } catch (err) {
        return false; // definitely offline
      }
    };
    this.listenToFirebaseConnexionState = () => {
      window.addEventListener('offline', async () => {
        const result = await checkOnlineStatus();
        console.log('Connection state: ', 'offline(', !result, ')');
        GlobalStore.setConnexion(result);
      });
      window.addEventListener('online', async () => {
        console.log('Connection state: ', 'online');
        GlobalStore.setConnexion(true);
      });
    };
  }
}

export default new GameAPI();