import React from 'react';
import PadlockOpen from '../../../assets/images/padlock-open.svg';
import PadlockLocked from '../../../assets/images/padlock-locked.svg';
import AppUtils from '../../../helpers/AppUtils';

const GameHUDClue = (props) => {
  const { number, price, names, isOpen, neededPlayers, onClick, isEnabled} = props;
  const askedToSeeThisClue = 'asked to see this clue';
  const neededPlayersMsg = `You need ${neededPlayers} more to unlock it`;
  const nbNames = names.length;
  const clueName = number === 4 ? 'SOLUTION' : `CLUE #${number}`;
  let namesEnumeration = 'Nobody';
  let clueMsg = '';
  
  if (names && nbNames > 0) {
    if (nbNames === 1) {
      namesEnumeration = names[0]
    } else {
      namesEnumeration = '';
      for (let i = 0; i < nbNames; i++) {
        if (i === nbNames - 1) {
          namesEnumeration += ` and ${names[i]}`;
        } else {
          namesEnumeration += names[i];
          if (names[i + 1] !== names[nbNames - 1]) {
            namesEnumeration += `, `;
          }
        }
      }
    }
  }
  clueMsg = `${namesEnumeration} ${askedToSeeThisClue}`;
  
  return (
    <div className={ isEnabled ? 'game-hud-clue' : 'game-hud-clue game-hud-clue-disabled' } >
      <button className={ !isOpen ? 'locked' : 'opened'} onClick={ () => { onClick(isOpen, number, price) } } disabled={ !isEnabled } >
        <span className="clue-name">
          { clueName }
        </span>
        <span className="clue-value">{ `-${AppUtils.formatNumber(price)} PTS` }</span>
        {
          isOpen &&  <img className="padlock-open" src={ PadlockOpen } alt="" />
        }
        {
          !isOpen &&  <img className="padlock-close" src={ PadlockLocked } alt="" />
        }
      </button>
      <p className="clue-msg">
        { isOpen ? 'This clue has been unlocked' : clueMsg }
        {
          neededPlayers > 0 && (
            <React.Fragment>
              <br />
              { neededPlayersMsg }
            </React.Fragment>
          )
        }
      </p>
    </div>
  );
};

export default GameHUDClue;