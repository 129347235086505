import React from 'react'
import RootLevel from './RootLevel'

const Room3 = () => {
  return (
    <div className="room-game-container">
      <RootLevel room={ 3 } />
    </div>
  );
};

export default Room3;
