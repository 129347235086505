import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import UserStore from '../../../stores/UserStore';
import ModalStore from '../../../stores/ModalStore';
import Timer from '../../common/Timer';
import GameHUDClue from './GameHUDClue';
import RoomProgress from '../../common/RoomProgress';
import ModalMessage from '../../common/ModalMessage';
import { LABELS, MESSAGES } from '../../../conf/app-conf';
import TeamAPI from '../../../services/TeamAPI';
import GameAPI from '../../../services/GameAPI';
import GlobalStore from '../../../stores/GlobalStore';
import GameClue from '../rooms/GameClue';
import AppUtils from '../../../helpers/AppUtils';
import SignOutButton from '../../common/SignOutButton';

const GameHUD = observer(({ teamStore, gameStore }) => {
  let me = {
    uid: UserStore.uid,
    firstname: `${UserStore.firstname} (you)`,
    isUser: true
  };
  const totalPlayers = teamStore.players ? Object.entries(teamStore.players).length : 0;
  const duration = gameStore.gameStatus.duration;
  const roomData = teamStore.currentRoomData;
  const allClues = toJS(roomData.clues);

  const onClickClue = (isOpen, clueNum, cluePrice) => {
    // If already open show clue
    if (isOpen) {
      ModalStore.showModal({
        content: <GameClue roomId={ teamStore.currentRoom }  clueId={ `clue${clueNum}` } />,
        cancelLabel: null,
        validateLabel: null,
        onValidate: () => {}
      });
      return;
    }

    // Check if user is among voters of the clue
    if (allClues[`clue${clueNum}`].voters) {
      const votersKeys = Object.keys(allClues[`clue${clueNum}`].voters);
      if (votersKeys.includes(me.uid)) {
        ModalStore.showModal({
          content: <ModalMessage message={ MESSAGES.CLUE_ALREADY_ASKED } />,
          cancelLabel: null,
          validateLabel: LABELS.OK,
          onValidate: () => {}
        });
        return;
      }
    }

    // Check budget
    if (teamStore.score - cluePrice <= 0) {
      ModalStore.showModal({
        content: <ModalMessage message={ MESSAGES.CLUE_OUT_OF_BUDGET } />,
        cancelLabel: null,
        validateLabel: LABELS.OK,
        onValidate: () => {}
      });
      return;
    }

    TeamAPI.addPlayerToVotersForClue({
      teamCode: teamStore.teamCode,
      roomId: teamStore.currentRoom,
      clueId: `clue${clueNum}`
    }, () => {
    }, () => {
    });
  };

  const roomClues = ((clues) => {
    let result = [];
    if (clues) {
      const nbClues = Object.entries(clues).length;
      const cluesOrdered = [];
      for (let i = 1; i <= nbClues; i++) {
        const tmp = clues[`clue${i}`];
        
        if (tmp) {
          let isEnabled = false;
          const previous = clues[`clue${i - 1}`];
          if ((previous && previous.status !== 0) || i === 1) {
            isEnabled = true;
          }
          cluesOrdered.push({
            number: i,
            isEnabled,
            ...tmp
          });
        }
      }
      console.log('cluesOrdered -> ', cluesOrdered);
  
      result = cluesOrdered.map((clueObj) => {
        const names = [];
        let neededPlayers = 0;
        if (clueObj.voters) {
          let nbVoters = 0;
      
          // For each voter
          for (const key in clueObj.voters) {
            nbVoters += 1;
            // We find his name in teamStore.players
            const playerInfo = teamStore.players.find((p) => {
              return p.id === key;
            });
            if (playerInfo) {
              if (playerInfo.id === me.uid) {
                names.push('You');
              } else {
                names.push(_.capitalize(playerInfo.firstname));
              }
            }
          }
      
          neededPlayers = Math.ceil(totalPlayers / 2) - nbVoters;
      
        }
    
        /*number, pointValue, names, isOpen, neededPlayers*/
        return {
          onClick: onClickClue,
          number: clueObj.number,
          isEnabled: clueObj.isEnabled,
          names,
          price: clueObj.price,
          isOpen: clueObj.status === 1,
          neededPlayers
        }
      });
    }
    return result;
  })(allClues);

  let others = null;
  let playersList = [me];

  if (teamStore.players) {
    others = teamStore.players.filter((p) => {
      return p.id !== me.uid;
    });

    if (others && others.length > 0) {
      playersList = playersList.concat(others.map((p) => {
        const tmpObj = toJS(p);
        tmpObj.isUser = tmpObj.uid === me.uid;
        return tmpObj;
      }));
    }
  }
  // Players two by two
  playersList = _.chunk(playersList, 2);

  // If time is elapsed
  const onDurationElapsed = async () => {
    await GameAPI.onDurationElapsed(GlobalStore, () => {
      GlobalStore.setCurrentPageByStatus(gameStore.gameStatus.status);
    });
  };

  // On clikc help
  const onHelp = () => {
    GlobalStore.showHelp();
  };

  return (
    <div className="game-hud-wrapper">
      <div className="blocks-wrapper">
        { /* ROOMS'S STEP */ }
        <div className="block">
          <div className="title">
            <h1>JOURNEY</h1>
            <h1>{ teamStore.teamName }</h1>
          </div>
          <RoomProgress cssTheme="room-progress room-progress-theme-hud" rooms={ teamStore.roomsList }/>
        </div>
        { /* PLAYERS */ }
        <div className="block">
          <div className="title">
            <h1>Players</h1>
          </div>
          <div className="players-list">
            <div className="col col1">
              {
                playersList.map((tuple) => {
                  let first = tuple[0];
                  const css1 = first.isUser ? 'name-cell current-user' : 'name-cell';

                  return (<p key={ uuidv4() } className={ css1 }>{ first.firstname } </p>);
                })
              }
            </div>
            <div className="col col2">
              {
                playersList.map((tuple) => {
                  const second = tuple[1];
                  const css2 = second && second.isUser ? 'name-cell current-user' : 'name-cell';

                  return(
                    <p key={ uuidv4() } className={ css2 }>{ second && second.firstname }</p>
                  );
                })
              }
            </div>
          </div>
        </div>
        { /* TIMER & SCORE */ }
        <div className="block">
          <div className="timer-score-wrapper">
            <div className="timer-block">
              <div className="title">
                <h1>Timer</h1>
              </div>
              <p><Timer duration={ duration } onDurationElapsed={ onDurationElapsed }/></p>
            </div>
            <div className="score-block">
              <div className="title">
                <h1>Score</h1>
              </div>
              <p>{ AppUtils.formatNumber(teamStore.score) }<span className="unit">{ teamStore.score > 0 ? ' pts': ' pt'}</span></p>
            </div>
          </div>
        </div>
        { /* CLUES */ }
        <div className="block">
          <div className="title">
            <h1>IF YOU ARE BLOCKED</h1>
          </div>
          {
            roomClues && roomClues.length && (
              <div className="clues-container">
                {
                  roomClues.map((clueObj) => {
                    const obj = { ...clueObj };
                    console.log()
                    if (obj.isOpen) {
                      obj.neededPlayers = 0;
                    }
                    return (
                      <GameHUDClue { ...obj } key={ uuidv4() } />
                    );
                  })
                }
              </div>
            )
          }
        </div>
        {/* HELP & SING OUT */}
        <div className="block help-sign-out">
          <button className="btn-help" onClick={ onHelp }><span>MORE HELP</span></button>
          <SignOutButton />
        </div>
      </div>
    </div>
  );
});

export default GameHUD;
