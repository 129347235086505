import React, { useState } from 'react'
import RootLevel from './RootLevel'
import InputForm from '../../common/InputForm'
import GlobalStore from '../../../stores/GlobalStore'
import GameAPI from '../../../services/GameAPI'
import { MESSAGES } from '../../../conf/app-conf'
import RoomBonus from './RoomBonus'
import AppUtils from '../../../helpers/AppUtils';
import GameStore from '../../../stores/GameStore';
import ServerTime from '../../../stores/ServerTime';

const Room4 = ({ teamStore }) => {
  const [word, setWord] = useState('')
  const [enabled, setEnabled] = useState('')
  const [errorMsg, setErrorMsg] = useState(null)
  const fieldName = 'roomcode'
  let intervalID = null
  let getBonus = null;

  const inputHandler = (evt) => {
    if (evt.target) {
      setWord(evt.target.value)
      setEnabled(evt.target.value && evt.target.value !== '' && evt.target.value.trim() !== '')
    }
  }

  const checkSolution = async (code) => {
    GlobalStore.setLoadingState(true)
    let bonusPoints = 0;
    if (getBonus) {
      bonusPoints = getBonus();
    }
    try {
      const result = await GameAPI.validateSolution(code, teamStore.teamCode, 'room4', bonusPoints)
      if (!result.isCorrect) {
        setErrorMsg(MESSAGES.SOLUTIONS.room4)
      } else {
        if (intervalID) {
          clearInterval(intervalID)
        }
      }
    } catch (errorObj) {
      if (errorObj.details) {
        setErrorMsg(errorObj.details)
      }
    }
    GlobalStore.setLoadingState(false)
  }

  return (
    <div className="room-game-container">
      <RootLevel room={ 4 } />

      <div className="room-form">
        <div className="label-wrapper">
          <p>Which obsession of Frank Gehry’s influenced his style?</p>
        </div>
        <InputForm
          inputHandler={ inputHandler }
          onValidate={ () => { checkSolution(word) } }
          fieldName={ fieldName }
          fieldValue={ word }
          enabled={ enabled }
          errorMsg={ errorMsg }
        />
        <RoomBonus getterBonus={ (func) => {
          getBonus = func;
        } } initBonus={ AppUtils.getBonusPoints(GameStore.conf.rooms.room4.nbPointsBonus, ServerTime.time) }/>
      </div>
    </div>
  )
}

export default Room4
