import React from 'react';
import SelectOn from '../../../assets/images/icon-select-on.svg';
import SelectOff from '../../../assets/images/icon-select-off.svg';

const QuizItemResponse = ({ selected, response, onSelect, id, correct }) => {
  return (
    <button className="btn-response-item" onClick={ () => { onSelect(id, correct); } }>
      <img src={ selected ? SelectOn : SelectOff } alt="" />
      <span>{ response }</span>
    </button>
  );
};

export default QuizItemResponse;