import React, { useState } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { observer } from 'mobx-react';
import Timer from '../../common/Timer';
import GameAPI from '../../../services/GameAPI';
import { MESSAGES, QUIZ } from '../../../conf/app-conf';
import LogoLVMHCompliance from '../../common/LogoLVMHCompliance';
import QuizItem from './QuizItem';
import GlobalStore from '../../../stores/GlobalStore';

const Quiz = observer(({ gameStore, globalStore, teamStore }) => {
  // Get mn left
  const { duration } = gameStore.gameStatus;
  // If time is out
  const onDurationElapsed = async () => {
    console.log('onDurationElapsed !!!!> ');
    await GameAPI.onDurationElapsed(globalStore,() => {
      console.log('fuck!!!!> gameStore.gameStatus.status ', gameStore.gameStatus.status);
      globalStore.setCurrentPageByStatus(gameStore.gameStatus.status);
    });
  };
  // Quiz state
  const [quizData, setQuizData] = useState(_.cloneDeep(QUIZ).map((qObj) => {
    const item = { ...qObj };
    item.responses = _.shuffle(item.responses).map((respObj, index) => {
      return {
        ...respObj,
        id: index,
        selected: false
      }
    });
    return item;
  }));
  // Error state
  const [errorMsg, setErrorMsg] = useState();
  
  const submitSolution = async () => {
    GlobalStore.setLoadingState(true);
    
    try {
     await GameAPI.validateSolution('1', teamStore.teamCode, 'room0');
    } catch (errorObj) {
      if (errorObj.details) {
        setErrorMsg(errorObj.details);
      }
    }
    GlobalStore.setLoadingState(false);
  };
  
  const onQuestionAnswered = (idQuestion, hasCorrectAnswer, indexResponse) => {
    setQuizData((currentValue) => {
      const result = [...currentValue];
      const targetQuestion = result.find((o) => { return o.id === idQuestion; });
      targetQuestion.hasCorrectAnswer = hasCorrectAnswer;
      targetQuestion.hasAnswered = true;
      targetQuestion.responses = targetQuestion.responses.map((respObj, index) => {
        return {
          ...respObj,
          selected: index === indexResponse
        }
      });
      
      // Check Result
      let hasAnsweredAll = true;
      let allGood = true;
      
      for (const question of result) {
        if (!question.hasAnswered) {
          hasAnsweredAll = false;
        }
        if (!question.hasCorrectAnswer) {
          allGood = false;
        }
      }
      if (hasAnsweredAll && !allGood) {
        setErrorMsg(MESSAGES.QUIZ_WRONG);
      } else {
        setErrorMsg(null);
      }
      if (allGood) {
        submitSolution();
      }
      
      return result;
    });
  };
  
  return (
    <div className="quiz-container">
      <LogoLVMHCompliance />
      <div className="quiz-content-wrapper">
        <p className="intro">Before entering the game please answer the following questions correctly. Beware, your countdown starts now...</p>
        <div className="quiz-wrapper">
          {
            quizData.map((quizItemObj) => {
              const cp = {
                ..._.cloneDeep(quizItemObj),
                onQuestionAnswered,
              };
              return <QuizItem quizItemObj={ cp } key={ uuidv4() } />
            })
          }
        </div>
        <Timer duration={ duration } onDurationElapsed={ onDurationElapsed } isHidden={ true } />
      </div>
      {
        errorMsg && <p className="error-message">{ errorMsg }</p>
      }
    </div>
  );
});

export default Quiz;