import { makeAutoObservable } from 'mobx';
import { GAME_STATUS, PAGES } from '../conf/app-conf';
import UserStore from './UserStore';


class GlobalStore {
  isLoading = false;
  isHelp = false;
  isConnected = true;
  currentPage = {};
  
  constructor(props) {
    makeAutoObservable(this)
  }
  
  setLoadingState = (loadingState) => {
    this.isLoading = loadingState;
  };
  
  showHelp = () => {
    this.isHelp = true;
  };
  
  hideHelp = () => {
    this.isHelp = false;
  };
  
  setConnexion = (state) => {
    this.isConnected = state;
  };
  
  setCurrentPage = (pageObj) => {
    this.currentPage = { ...pageObj };
  };
  
  setCurrentPageByStatus = (status) => {
    // Go to page according to game status
    if (status === GAME_STATUS.COMING || status === GAME_STATUS.TO_START) {
      this.setCurrentPage(PAGES.HOW_TO_PLAY);
    }
    if (status === GAME_STATUS.STARTED) {
      // Check if we not have team
      if (UserStore.firstname) {
        this.setCurrentPage(PAGES.GAME);
      } else {
        this.setCurrentPage(PAGES.USERNAME);
      }
    }
    if (status === GAME_STATUS.ENDED) {
      this.setCurrentPage(PAGES.GAME);
    }
  }
}

export default new GlobalStore();