import { FirebaseFunctions, FirebaseDB } from './FirebaseAPI';
import {GAME_STATUS, MESSAGES} from '../conf/app-conf';
import GlobalStore from '../stores/GlobalStore';
import TeamStore from '../stores/TeamStore';
import UserAPI from './UserAPI';
import UserStore from '../stores/UserStore';
import GameStore from '../stores/GameStore';
import GameAPI from './GameAPI';
import ServerTime from '../stores/ServerTime';

class TeamApi {
  constructor() {
    const checkTeamCodeFunc = FirebaseFunctions.httpsCallable('checkTeamCode');
    const addPlayerToTeamFunc = FirebaseFunctions.httpsCallable('addPlayer');
    const removePlayerToTeamFunc = FirebaseFunctions.httpsCallable('removePlayer');
    const openClueFunc = FirebaseFunctions.httpsCallable('openClue');
    const setRoomOpeningDateFunc = FirebaseFunctions.httpsCallable('setRoomDateOpening');
    let currentTeamRef = null;
    
    /*
    * Set serverTime
    * */
    this.setServerTime = async () => {
      // Server date
      const serverTime = await GameAPI.getServerDate();
      console.log('serverTime ==> ', serverTime);
      if (serverTime) {
        GameStore.setConf({ serverTime });
      }
    };
    
    /**
     * Check team
     * Si invalide : erreur
       Si valide :
       - mais partie à venir : page pour revenir plus tard
       - mais partie terminée : erreur - partie terminé
   
   
       Si date/heure partie passée   (cad que la partie est en cours ou en démarrage)
       - si équipe complète :  erreur (on ne peut pas avoir plus de n participants par équipe)
       - si équipe pas complète  étape suivante de saisie du prénom
     * */
    this.checkTeamCode = (codeObj, onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      
      checkTeamCodeFunc(codeObj).then(async (response) => {
        GlobalStore.setConnexion(true);
        if (response && response.data && response.data.teamData) {
          const { code, name } = response.data.teamData;
          TeamStore.init({
            teamCode: code,
            teamName: name
          });
          
          GameStore.init(response.data.gameData);
          
          // Update time server
          ServerTime.setTime(response.data.serverTime);
          
          // Get status
          const { status } = GameStore.gameStatus;
          if (status === GAME_STATUS.ENDED) {
            onError(MESSAGES.GAME_ALREADY_ENDED);
          } else {
            onSuccess(status);
          }
        } else {
          console.error('checkTeamCode error--> ', response.data);
          if (response.data && response.data.details) {
            onError(response.data.details);
          } else {
            onError();
          }
        }
      }).catch((errorObj) => {
        console.error('checkTeamCode > errorObj > ', errorObj);
        onError(MESSAGES.INTERNAL_ERROR);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
    
    /**
     * Add player to team
     * */
    this.addPlayer = (payload, onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      // First, we log in anonymously so we have uid to register
      UserAPI.signIn().then((response) => {
        // Second, we add user
        this.signIn({
          firstname: payload.firstname,
          teamcode: payload.code
        }, onSuccess, onError)
      }).catch((errorObj) => {
        onError(errorObj);
        GlobalStore.setLoadingState(false);
      });
    };
    
    /**
     * Remove player off the team
     * */
    this.removePlayer = (teamCode, onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      removePlayerToTeamFunc({ teamCode }).then((response) => {
        console.log('removePlayerToTeamFunc > response: ', response);
      }).finally(() => {
        UserAPI.signOut().then((responseSignOut) => {
          onSuccess(responseSignOut);
        }).finally(() => {
          GlobalStore.setLoadingState(false);
        });
      });
    };
    
    /**
     * Cloud function for add player
     * */
    this.signIn = (payload, onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      addPlayerToTeamFunc({...payload}).then(async (response) => {
        GlobalStore.setConnexion(true);
        if (response && response.data && response.data.httpErrorCode) {
          onError(response.data);
        } else {
          ServerTime.setTime(response.data.serverTime);
          
          // Parse user data
          UserStore.init({...response.data.userData});
          
          // Team info
          TeamStore.init({
            teamCode: response.data.userData.teamcode,
            teamName: response.data.userData.teamname
          });
  
          // Parse game data
          GameStore.init(response.data.gameData);
  
          // Update time server
          ServerTime.setTime(response.data.serverTime);
          
          onSuccess(response.data);
        }
      }).catch((addPlayerError) => {
        onError(addPlayerError);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
    
    /**
     * Add player to voters for clue
     * */
    this.addPlayerToVotersForClue = (params, onSuccess, onError) => {
      GlobalStore.setLoadingState(true);
      openClueFunc(params).then((response) => {
        GlobalStore.setConnexion(true);
        if (response && response.data && response.data.code) {
          onError(response.data);
        } else {
          onSuccess(response.data);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
    
    /**
     * Set room's opening date
     * */
    this.setRoomOpening = (payload) => {
      setRoomOpeningDateFunc(payload).then((response) => {
        console.log('setRoomOpening > response: ', response);
      })
    };
    
    /**
     * Start listen to team data
     * */
    this.listenToTeamData = (dbPath, cb) => {
      currentTeamRef = FirebaseDB.ref(dbPath);
      
      if (currentTeamRef) {
        currentTeamRef.on('value', async (dataSnapshot) => {
          if (dataSnapshot.exists()) {
            const data = dataSnapshot.val();
            const teamCode = dataSnapshot.key.split('-')[2];
            // console.log('TEAM DATA ==> ', data);
            TeamStore.init({ teamCode, ...data });
            cb(true);
          }
        });
      } else {
        cb(false)
      }
    };
    
    /**
     * Stop listen to team data
     * */
    this.stopListenToTeamData = () => {
      if (currentTeamRef) {
        currentTeamRef.off('value');
      }
    }
  }
}

export default new TeamApi();