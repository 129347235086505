import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { MESSAGES, PAGES } from '../../conf/app-conf';
import RoomProgress from '../common/RoomProgress';
import AppUtils from '../../helpers/AppUtils';
import SignOutButton from '../common/SignOutButton';
import AnimInterRoom from '../../assets/images/inter-room-anim.gif';
import ModalStore from '../../stores/ModalStore';

const EndRoom = observer((props) => {
  const { teamStore, globalStore, gameStore } = props;
  const { status, duration } = gameStore.gameStatus;
  const msgRef = useRef(null);
  let unlockerMsg = null;
  let mainMsg = MESSAGES.END_ROOM[teamStore.currentRoom];
  let loser = false;
  
  // We check if game is already over ->  status === 2
  if (status === 2) {
    // Check if last room has NOT an unlocker -> it isn't resolved -> team lose
    if (!teamStore.rooms.room4['unlocker-player']) {
      mainMsg = MESSAGES.END_ROOM.lose;
      loser = true;
    } else {
      mainMsg = MESSAGES.END_ROOM.room4;
    }
  }
  
  // Hide help if shown
  if (globalStore.isHelp) {
    globalStore.hideHelp();
  }
  
  // Write msg
  useEffect(() => {
    let timeoutId = null;
    if (msgRef) {
      msgRef.current.innerHTML = mainMsg;
    }
    if (teamStore.currentRoom === 'room4' || loser) {
      timeoutId = setTimeout(() => {
        if (ModalStore.content) {
          ModalStore.hideModal();
        }
        globalStore.setCurrentPage(PAGES.HALL_OF_FAME);
      }, 10000);
    }
    
    return () => {
      clearTimeout(timeoutId);
    }
  }, []);
  
  // Filter
  const checkRoomList = (roomItem) => {
    const tmp = { ...roomItem };
    if (loser) {
      tmp.isNext = false;
    }
    return tmp;
  };
  
  return (
    <div className="end-room-wrapper">
      {
        teamStore.currentRoomUnlockerPlayer && (
          <div className="unlocker-msg">
            <span>{ teamStore.currentRoomUnlockerPlayer.firstname } </span>
            { teamStore.currentRoom === 'room1' && MESSAGES.UNLOCKER_1 }
            { teamStore.currentRoom !== 'room1' && MESSAGES.UNLOCKER_2 }
          </div>
        )
      }
      <h1 ref={ msgRef }/>
      {
        !loser && (
          <p className="here-is">
            { teamStore.currentRoom === 'room4' && 'HERE IS YOUR TEAM SCORE' }
            { teamStore.currentRoom !== 'room4' && teamStore.currentRoom !== 'room0' && 'HERE IS A NEW ROOM TO ESCAPE' }
            {
              teamStore.currentRoom === 'room4' && (
                <React.Fragment>
                  <br />
                  <span>
                  {
                    `${AppUtils.formatNumber(teamStore.score)} PTS`
                  }
                  </span>
                </React.Fragment>
              )
            }
          </p>
        )
      }
      {
        teamStore.currentRoom !== 'room0' && <RoomProgress cssTheme="room-progress room-progress-theme-end-room" rooms={ teamStore.roomsList.map(checkRoomList) }/>
      }
      <div className="anim-wrapper">
        <img src={ AnimInterRoom } alt='' className="anim-inter-room" />
      </div>
      {
        (teamStore.currentRoom === 'room4' || loser) && <SignOutButton theme="dark" />
      }
    </div>
  );
});

export default EndRoom;